import React, {FC} from 'react';
import {useHistory, useRouteMatch} from "react-router";
import {Auth} from "aws-amplify";
import cn from "classnames";
import s from "./CompleteRegistration.module.css";

import base64 from "base-64";

import {handleSignIn, logout} from "../../../helpers/authenticationHelpers";
import {useGymContext} from "../../../context/GymContext";
import Input from '../../../components/Inputs/Input';
import {Button} from 'rsuite';
import { Link } from 'react-router-dom';
import {useMutation} from "@apollo/client";
import { RESET_PASSWORD } from '../../../apollo/accountOperations';

interface IOwnProps {

}

const CompleteRegistration: FC<IOwnProps> = (props) => {
  const {params} = useRouteMatch<{ email?: string, userToken?: string }>();
  const [email, setEmail] = React.useState(params?.email);
  const [tokenInvalid, setTokenInvalid] = React.useState(false);
  const [passwordHasBeenReset, setPasswordHasBeenReset] = React.useState(false);
  // const [user, setUser] = React.useState<any>();
  const [error, setError] = React.useState("");
  const [password, setPassword] = React.useState("");
  const history = useHistory();
  const {setGymToken} = useGymContext();
  
  const [resetPassword] = useMutation(RESET_PASSWORD);
  
  const tempPassword = base64.decode(params.userToken || "");
  
  const updateEmail = (newEmail: string) => setEmail(newEmail.replace(/\s+/g, ''))
  
  React.useEffect(() => {
    logout();
    setGymToken("");
  }, [setGymToken]);
  
  React.useEffect(() => {
    if (params.email) {
      Auth.signIn(params.email, tempPassword)
        .then(async result => {
          // console.log("*** SIGN IN RESULT", result);
          // setUser(result);
          if(result.challengeName !== "NEW_PASSWORD_REQUIRED"){
            await handleSignIn();
            history.push("/");
          }
        })
        .catch(error => {
          console.log("*** SIGN IN ERROR ***", error);
          setTokenInvalid(true);
          // history.push("/");
        })
    }
  }, [params.email])
  
  const checkPasswordIsValid = () => (/[a-z]/.test(password)) && (/[A-Z]/.test(password)) && (/[0-9]/.test(password))
  
  const register = async () => {
    // let hasError = false;
    if (!checkPasswordIsValid()) {
      setError("Password must contain upper & lowercase letters and numbers");
      return;
    } else {
      setError("");
    }
    if(password === tempPassword){
      setError("You may not use your previous / temporary password");
      return;
    }
    Auth.signIn(params.email ?? "", base64.decode(params.userToken || ""))
      .then(res => {
      Auth.completeNewPassword(res, password)
        .then(async result => {
          await handleSignIn();
          history.push("/")
      
        })
        .catch(error => {
          setError(error.message ?? "An error occurred")
        });
    })
  };
  
  const _resetPassword = async () => {
    resetPassword({variables: {email}})
      .then(result => {
        console.log("*** RESEND RESULT ***");
        console.log(result);
      })
      .catch(error => {
        console.log("*** RESET PASSWORD ERROR ***");
        console.log(error)
      })
      .finally(() => {
        setPasswordHasBeenReset(true);
      })
  }

  
  const renderForm = () => {
    return (
      <React.Fragment>
        <h2 style={{color: "var(--off-white)"}}>Email</h2>
        <Input
          disabled
          style={{
            color: "var(--brand-primary)",
            borderRadius: "var(--default-border-radius)",
            padding: "0.5rem",
            width: "100%"
          }}
          placeholder={"E-mail"}
          value={email}
          onChange={updateEmail}
        />
        <h2 style={{color: "var(--off-white)"}}>Temp Password</h2>
        <Input
          style={{
            color: "var(--brand-primary)",
            borderRadius: "var(--default-border-radius)",
            padding: "0.5rem",
            width: "100%",
            border: error ? "3px solid var(--brand-warn)" : "undefined"
          }}
          disabled={true}
          value={tempPassword}
          type={"password"}/>
        <h2 style={{color: "var(--off-white)"}}>Create Password</h2>
        <Input
          style={{
            color: "var(--brand-primary)",
            borderRadius: "var(--default-border-radius)",
            padding: "0.5rem",
            width: "100%",
            border: error ? "3px solid var(--brand-warn)" : "undefined"
          }}
          placeholder={"8+ chars, incl. uppercase, lowercase and number"}
          value={password}
          onChange={setPassword}
          type={"password"}/>
        <div style={{marginBottom: 40, paddingTop: "0.5rem"}}>
          <p style={{paddingBottom: "0.rem"}}>8+ characters, including upper & lowercase letters and numbers</p>
        </div>
        <Button style={{marginBottom: 20}} onClick={register}>Submit</Button>
        <br/>
        
        <div style={{color: "var(--brand-warn)", paddingTop: "0.5rem"}}>{error}</div>
      </React.Fragment>
    )
  }
  
  const renderInvalidTokenError = () => {
    return (
      <div>
        <p style={{color: "var(--brand-warn)"}}>This link is invalid or has expired. Please contact <a
          href={"mailto:info@vivifit.co?subject=Invalid%20signup%20link"} style={{color: "white"}}>info@vivifit.co</a> for help.</p>
        <br/><br/>
  
        <Link to={"#"} style={{color: "white", paddingTop: "0.5rem"}} onClick={_resetPassword}>Send password reset</Link>
        <br/>
        or
        <br/>
        <Link to={"/"} style={{color: "white", textDecoration: "underline"}}>Try logging in again</Link>
      </div>
    )
  }
  
  
  
  if(passwordHasBeenReset){
    return (
      <div className={cn(s.bg)}>
        <div className={cn(s.surround)}>
          <h1>Complete registration</h1>
          <div style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
            <div style={{paddingBottom: 60, paddingTop: 20}}>
              <p style={{color: "var(--brand-warn)"}}>If the address has been found, a temporary password has been sent by email. Please check your email and your spam folder</p>
              <br/><br/>
              <Link to={"/"} style={{color: "white", textDecoration: "underline"}}>Back to log in</Link>
            </div>
          </div>
        </div>
      </div>
    );
  }
  
  return (
    <div className={cn(s.bg)}>
      <div className={cn(s.surround)}>
        <h1>Complete registration</h1>
        <div style={{flex: 1, display: "flex", flexDirection: "column", justifyContent: "space-around"}}>
          <div style={{paddingBottom: 20, paddingTop: 20}}>
            {tokenInvalid && renderInvalidTokenError()}
            {!tokenInvalid && renderForm()}
          </div>
        </div>
      </div>
    </div>
  );
};


export default CompleteRegistration;
