import {Authenticator, useAuthenticator} from "@aws-amplify/ui-react";
import cn from "classnames";
import s from "./Registration.module.scss";
import {ProductOverviewScreens} from "./GymJoin/components/ProductOverviewScreens";
import React from "react";
import PasswordResetComponent from "../../../containers/Authenication/PasswordResetComponent";
import Loading from "../../../components/Loading";
import {useLocation} from "react-use";
import {checkIsMainSite} from "../../../helpers/checkIsMainSite";
import {useDomainContext} from "../../../helpers/DomainContext";

const FORM_FIELDS = {
  signIn: {
    username: {
      placeholder: 'Email Address',
      isRequired: true,
      label: 'Email'
    },
    password: {
      isRequired: true,
      label: 'Password'
    },
  }
}

export const CustomAuthenticator: React.FC = ({children}) => {
  const {user, toSignIn, route, authStatus} = useAuthenticator((context) => [context.user, context.route, context.authStatus, ]);
  
  const {hostname} = useLocation();
  const {branding} = useDomainContext();
  
  const isMainSite = checkIsMainSite(hostname);
  
  if (authStatus === "configuring") {
    return (
      <>
        <div style={{display: "none"}}>
          <Authenticator
            formFields={FORM_FIELDS}
          />
        </div>
        <Loading backgroundColor={"black"}/>
      </>
    )
  }
  
  if (!user || authStatus === "unauthenticated") {
    let content;
    switch (route) {
      case "resetPassword":
        content = <PasswordResetComponent onBack={toSignIn}/>
        break;
      default:
        content = <Authenticator formFields={FORM_FIELDS}/>
    }
    
    return (
      <main className={cn(s.main)}>
        
        <section className={cn(s.light)}>
          <article style={{alignItems: "center", display: "flex", flexDirection: "column",}}>
            {
              <h1 style={{marginBottom: 60}}>{route === "resetPassword" ? "Reset Password" : "Log In"}</h1>
            }
            {content}
          
          </article>
        
        </section>
        <section className={cn(s.dark)}>
          <article className={cn(s.productSurround)}>
            {
              isMainSite && <ProductOverviewScreens/>
            }
            {
              !isMainSite && branding?.logoOnDarkUrl && <img src={branding.logoOnDarkUrl} alt={"Logo"} style={{maxWidth: 300, marginBottom: 60}}/>
            }
            
          </article>
        </section>
      </main>
    )
  }
  
  return <>{children}</>
}
