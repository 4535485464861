import React from "react";

export const useWorking = () => {
  const [working, setWorking] = React.useState(false);
  const [actionComplete, setActionComplete] = React.useState(false);
  
  React.useEffect(() => {
    if (!actionComplete) {
      return;
    }
    const timeout = setTimeout(() => {
      setActionComplete(false)
    }, 2000)
    return () => {
      clearTimeout(timeout);
    }
  }, [actionComplete]);
  
  const monitor = (f: () => Promise<any>) => {
    setWorking(true);
    return f()
      .then((result) => {
        setWorking(false);
        setActionComplete(true);
        return result;
      })
      .catch((e) => {
        setWorking(false);
        throw e;
      })
  }
  
  
  return {working, setWorking, actionComplete, monitor}
}
