import React from 'react';

import cn from "classnames";
import s from "./SelfRegister.module.css"


import emailSentAnimation from "../../../assets/lottie/email_sent.json";
import {Button, InputGroup, Message, Form, FormControl, Schema, FormGroup} from "rsuite";
import { Player } from '@lottiefiles/react-lottie-player';

import {useRouteMatch} from "react-router";
import {gql, useApolloClient, useQuery} from "@apollo/client";
import {BrandedSplitPage} from "../../../components/BrandedSplitPage/BrandedSplitPage";
import {VALIDATE_REGISTRATION_CODE} from "../../../apollo/gymOperations";
import ErrorDisplay from '../../../components/ErrorDisplay/ErrorDisplay';


const model = Schema.Model({
  firstName: Schema.Types.StringType().isRequired('First name is required.'),
  lastName: Schema.Types.StringType().isRequired('Last name is required.'),
  email: Schema.Types.StringType().isRequired('Please enter a valid email address.').isEmail('Please enter a valid email address.')
});


interface IOwnProps {
  onComplete?(): void;
}

const SELF_REGISTER = gql`
  mutation SelfRegister($registrationCode: String!, $gymCode: String!, $firstName: String!, $lastName: String!, $email: String!){
    selfRegister(registrationCode: $registrationCode, gymCode: $gymCode, firstName: $firstName, lastName: $lastName, email: $email){
      id
    }
  }
`;

export const SelfRegister = ({onComplete}: IOwnProps) => {
  const [state, setState] = React.useState<"LOADING" | "READY" | "INVALID" | "EMAIL_SENDING" | "EMAIL_SENT">("LOADING");
  const [emailInUse, setEmailInUse] = React.useState(false);
  // const [errors, setErrors] = React.useState<Record<string, string>>();
  const {params} = useRouteMatch<{ gymCode: string, registrationCode: string }>();
  const {registrationCode, gymCode} = params || {};
  
  const formRef = React.useRef<any>(null);
  const { error, loading, data, refetch } = useQuery<{validateRegistrationCode: InvocationResult}>(VALIDATE_REGISTRATION_CODE, {variables: {registrationCode, gymCode}});
  const apolloClient = useApolloClient();
  
  
  React.useEffect(() => {
    if(!!data?.validateRegistrationCode){
      setState(data?.validateRegistrationCode.success ? "READY" : "INVALID");
    }
  }, [data?.validateRegistrationCode])
  
  const handleSubmit = (formIsValid: boolean,  event: React.FormEvent<HTMLFormElement>) => {
    if(!formIsValid){
      return;
    }
  
    const form = new FormData(event.target as HTMLFormElement);
    const values: Record<string, string> = {};
  
    for (var pair of form.entries() as any) {
      values[pair[0]] = pair[1];
    }
    
    const variables =  {
      ...values,
      registrationCode,
      gymCode,
    };
    
    apolloClient.mutate({
      mutation: SELF_REGISTER,
      variables
    }).then(() => {
      setState("EMAIL_SENDING");
      onComplete?.()
    }).catch(error => {
      console.log({error}, error.message);
      if(error.message === "Email address is already in use"){
        setEmailInUse(true);
      }
    })
  }
  
  const TextField = ({name, label, accepter, ...rest}: any) => {
    // return <div />
    return (
      <FormGroup controlId={name}>
        {/*<FormControl>{label} </FormControl>*/}
        <InputGroup className={cn(s.textGroup)}>
          <InputGroup.Addon className={cn(s.addon)}>{label}</InputGroup.Addon>
          <FormControl className={cn(s.control)} name={name} accepter={accepter} {...rest} />
        </InputGroup>
      </FormGroup>
    )
  }
  
  
  const drawSending = () => {
    return (
      <div style={{margin: "3rem 0 2rem"}}>
        {/* @ts-ignore */}
        <Player
          autoplay={true}
          loop={false}
          onEvent={finishAnimation}
          src={emailSentAnimation}
          style={{ height: '300px', width: '300px' }}
        />
      </div>
    )
  }
  
  const finishAnimation = (e: string) => {
    // console.log({e})
    if(e === "complete"){
      setState("EMAIL_SENT")
    }
  }
  
  
  const renderSentMessage = () => (
    <Message description={"We've sent you an email - please check your junk / spam if you haven't received it in a few minutes"} />
  )
  
  return (
      <BrandedSplitPage center>
        <h1>Complete registration</h1>
        <ErrorDisplay error={error} loading={state === "LOADING"} refetch={refetch} />
        {
          state === "INVALID" && (
            <React.Fragment>
              <Message type={"error"} title={"Code not found"} description={"We're sorry - either this code doesn't exist, or the gym has disabled self-registration"}/>
            </React.Fragment>
          )
        }
        <Form ref={formRef} model={model} onSubmit={handleSubmit}>
          {state === "READY" && (
            <React.Fragment>
              <Message style={{margin: "2rem 0 1rem"}} description={"Welcome to viviFIT. Your journey starts here! Enter your details below to get started"} />
              <TextField name={"firstName"} label={"First Name"} />
              <TextField name={"lastName"} label={"Last Name"} />
              <TextField name={"email"} label={"Email Address"} />
              {
                emailInUse && (
                  <div style={{color: "red", marginTop: -20}}>Address is already in use</div>
                )
              }
              <Button type={"submit"} style={{marginTop: "1rem"}} color={"green"} icon={"send"} label={"Submit"}>Submit</Button>
            </React.Fragment>
          )}
        </Form>
        {
          (state === "EMAIL_SENDING" || state === "EMAIL_SENT") && (
           drawSending()
          )
        }
        {
          state === "EMAIL_SENT" && renderSentMessage()
        }
      </BrandedSplitPage>
    
  );
};

