import React from 'react';
import cn from "classnames";
import s from "../Registration.module.scss"
import {ProductOverviewScreens} from "./components/ProductOverviewScreens";
import {GymJoinForm} from "./components/GymJoinForm";
import {GymJoinContextProvider} from "./GymJoinContext";


export const GymJoin = ({type}: {type: "FULL" | "RECIPE"}) => {
  
  
  return (
    <GymJoinContextProvider type={type}>
      <main className={cn(s.main)}>
        <GymJoinForm />
        <section className={cn(s.dark)}>
          <article className={cn(s.productSurround)}>
            {
              type === "FULL" && <ProductOverviewScreens/>
            }
          </article>
        </section>
      </main>
    </GymJoinContextProvider>
  );
};
