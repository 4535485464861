import to from "await-to-js";
import {authenticateWithGym} from "../apollo/gymOperations";
import jwt from "jsonwebtoken";
import {Notification} from "rsuite";
import client from "../apollo/client";
import {Auth} from "aws-amplify";
import {authenticateCognito, authenticateProxy} from "../apollo/authQueries";

interface IAuthData {
  token?: string;
  roles?: string[];
  id?: string;
  clientProfileId?: string;
  accountStatus?: "READY" | "AWAITING_PROFILE" | "NOT_SET";
}


export const loginWithGym = async (gymId: string) => {
  
  const [error, authenticateWithGymResult] = await to(
    client.mutate({
      mutation: authenticateWithGym,
      variables: {gymId}
    })
  )
  
  if (error || !authenticateWithGymResult?.data?.authenticateWithGym?.token) {
    alert("Unable to log in to gym");
    console.log({error, authenticateWithGymResult});
    return "";
  }
  
  const tokenWithGymAccess = authenticateWithGymResult?.data?.authenticateWithGym?.token;
  
  const valid = ((jwt.decode(tokenWithGymAccess) as any) ?? {gymId: null}).gymId === gymId;
  
  if (!valid) {
    Notification.error({title: "Unable to access gym"});
    return "";
  }
  
  window.localStorage.setItem("@gym:token", tokenWithGymAccess);
  
  return tokenWithGymAccess;
}

export const logInCognitoProxy = async (clientProfileId?: string) => {
  if(!clientProfileId){
    alert("No client profile id");
    return Promise.reject({
      success: false,
      error: "No client profile id"
    });
  }
  const cognitoToken = await Auth.currentSession().then(session => session.getIdToken().getJwtToken());
  console.log({cognitoToken})
  // if(!authData?.roles?.includes("SUPER_ADMIN")){
  //   alert("Not authorized");
  //   return Promise.reject({
  //     success: false,
  //     error: "Not authorized"
  //   });
  // }
  //
  return client.mutate({
    mutation: authenticateProxy,
    variables: {
      cognitoToken,
      clientProfileId
    }
  })
    .then(async response => {
      const data = response.data?.authenticateProxy!;
      console.log("auth proxy", JSON.stringify(data, null, 2));
      // await clearStorage();
      await client.resetStore();
      const {token} = data;
      await window.localStorage.setItem("@user:token", token!);
      // setAuthData(data);
      // _storeCredentials(data);
      
      return Promise.resolve({
        success: true,
        ...data
      })
    })
    .catch((error, ...rest) => {
      console.log("Auth error", {error, rest});
      return Promise.reject({
        success: false,
        error
      })
    });
}



export const handleSignIn = async () => {
  const [cognitoError, user] = await to(Auth.currentAuthenticatedUser());
  const cognitoToken = user?.signInUserSession?.idToken?.jwtToken;
  
  // console.log("cognito pool id", import.meta.env.VITE_AWS_USER_POOLS_ID)
  // console.log({cognitoToken})
  
  if (cognitoError || !cognitoToken) {
    Notification.error({title: "Unable to sign in", description: "Couldn't get token"})
  }
  
  const [error, prismaResult] = await to(
    client.mutate({
      mutation: authenticateCognito,
      variables: {
        cognitoToken
      }
    }).then(async response => {
      const {token} = response?.data?.authenticateCognito;
      window.localStorage.setItem("@user:token", token);
      return new Promise(resolve => {
        setTimeout(() => {
          resolve(response);
        }, 100)
      })
    })
  )
  
  if (error || !prismaResult) {
    Notification.error({title: "Unable to sign in", description: "Check the console for more"})
    console.log(error);
    logout();
    return false;
  }
  
  return prismaResult;
}


export const logout = () => {
  window.localStorage.clear();
  return client.clearStore()
    .then(() => {
      Auth.signOut();
      console.log("SIGNED OUT")
    })
}
