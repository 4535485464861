import { gql } from "@apollo/client";

export const ME_QUERY = gql`
  query Me {
    me {
      id
      settings
      clientProfile {
        id
        firstName
        lastName
        createdAt
        status
        imageUrl
        thumbnailUrl
        notificationSettings
        emailSettings
        preferredGym {
          id
          name
          branding {
            id
            colors
            logoUrl
            fontFamily
          }
          group {
            id
            preferences
            branding {
              id
              colors
              logoUrl
              fontFamily
            }
          }
        }
        gyms {
          id
        }
      }
      trainerProfile {
        id
      }
    }
    managedGyms {
      id
      name
      timeZone
      group {
        id
        name
      }
    }
  }
`;
export const MY_FULL_PROFILE = gql`
  query MyProfile {
    myProfile {
      id
      firstName
      lastName
      createdAt
      status
      imageUrl
      thumbnailUrl
      formResponses(where: { form: { required: true, status: ACTIVE } }) {
        id
        completedOn
        due
        form {
          id
          name
        }
      }
      gyms {
        id
        name
      }
      preferredGym {
        id
        group {
          id
          preferences
          appStoreUrl
          playStoreUrl
        }
      }
    }
    #    myPaymentInfo {
    #      id
    #      stripeCardStatus
    #      stripeCardPaymentMethod
    #    }
    myPaymentMethods
    authorisedUserRoles
  }
`;

export const fullClientProfile = gql`
  query FullClientProfile($clientProfileId: ID!) {
    clientDetails(id: $clientProfileId) {
      id
      firstName
      lastName
      timeZone
      gender
      dateOfBirth
      height
      weight
      bodyFat
      metabolism
      goal
      fitness
      sessionsPerWeek
      distastes
      #      skinfoldMeasurements
      #      girthMeasurements
      #      weightMeasurements
      #      photos
      imageUrl
      thumbnailUrl
      #      nutritionPlans
      #      workouts
      diabetic
      vegan
      vegetarian
      glutenIntolerant
      status
    }
  }
`;

export const updateClientProfile = gql`
  mutation UpdateClientProfile(
    $where: ClientProfileWhereUniqueInput!
    $data: ClientProfileUpdateInput!
  ) {
    updateClientProfile(where: $where, data: $data) {
      id
    }
  }
`;

export const SAVE_NOTIFICATION_SETTINGS = gql`
  mutation SaveNotificationSettings($settings: Json!) {
    saveNotificationSettings(settings: $settings) {
      id
      notificationSettings
    }
  }
`;

export const SAVE_EMAIL_SETTINGS = gql`
  mutation SaveEmailSettings($settings: Json!) {
    saveEmailSettings(settings: $settings) {
      id
      emailSettings
    }
  }
`;
