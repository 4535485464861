export const CURRENCY_SYMBOLS: Record<string, string> = {
  
  gbp: "£",
  usd: "$",
  eur: "€",
}

export const getCurrencySymbol = (name?: string) => name ? CURRENCY_SYMBOLS[name] || name : name;


export const STRIPE_CURRENCIES = [
  {label: "GBP", id: "gbp"},
  {label: "EUR", id: "eur"},
  {label: "USD", id: "usd"},
]
