import React from 'react';
import {useParams} from "react-router";
import cn from "classnames";
import s from "../Registration.module.scss";
import "@fontsource/rock-salt"

import {useNotification} from "../../../../hooks/useNotification";
import axios from "axios";
import {apiUri} from "../../../../config/api";
import {BrandArea} from "./components/BrandArea";
import ReactGA from "react-ga4";


export const FinishPackageRegistration = () => {
  const {gymId, checkoutSessionId, } = useParams<{gymId: string; checkoutSessionId: string; packageId: string}>();
  const [ready, setReady] = React.useState(false);
  

  const notify = useNotification();
  
  React.useEffect(() => {
    if(!gymId || !checkoutSessionId) {
      return;
    }
    ReactGA.event({
      label: "Package registration started",
      action: "Finish package registration",
      category: "customer-revenue",
      value: 1
    });
    notify({},
      axios.get<{customer: {email: string}}>(`${apiUri}/stripe/join-block-success/${gymId}/${checkoutSessionId}`)
        .then(() => setReady(true))
    )
  }, [gymId, checkoutSessionId])
  
  
  return (
    <main className={cn(s.main)}>
    
      <section className={cn(s.light)}>
        {
          ready ? (
            <article>
              <h1>All done!</h1>
              Check your email to get started, or <u><b><a href={"/"}>click here</a></b></u> to sign in!
            </article>
          ) : (
            <article>
              Working...
            </article>
          )
        }
      </section>
      <BrandArea gymId={gymId} />
  
    </main>
  );
};

interface IInputRowProps {
  label: string;
  value?: string;
  type?: "text" | "password";
  error?: string;
  disabled?: boolean;
  onChange(value: string): void;
}
