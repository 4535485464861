import { useTrainingPlan } from "@vivifit/fitdo-utils";
import { TextInput, View, Text, StyleSheet, TouchableOpacity } from "react-native";
import { observer } from "mobx-react-lite";
import { useState } from "react";

export const TrainingPlanHeader = observer(() => {
  const [editingName, setEditingName] = useState(false);
  const store = useTrainingPlan();


  const handleSaveName = () => {
    store.saveName().then(() => {
      setEditingName(false);
    }).catch(error => {
      alert(error.message);
    });
  }

  return (
    <View style={styles.container}>
      <Text style={styles.nameTitle}>Name</Text>
      {
        editingName ? (
          <View style={styles.nameInputSurround}>
            <TextInput style={styles.nameInput} value={store.name} onChangeText={(text) => store.setName(text)} />
            <TouchableOpacity
              style={[styles.nameSaveButton, !store.name && styles.nameSaveButtonDisabled]}
              onPress={handleSaveName}
              disabled={!store.name}
            >
              <Text style={styles.nameSaveButtonText}>Save</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => setEditingName(false)}>
              <Text>Cancel</Text>
            </TouchableOpacity>
          </View>
        ) : (
          <Text style={styles.nameDisplay} onPress={() => setEditingName(true)}>{store.name}</Text>
        )
      }
      {
        store.isDraft && (
          <>
            <TouchableOpacity onPress={() => store.forceSaveContent()}>
              <Text>Save</Text>
            </TouchableOpacity>
            <TouchableOpacity onPress={() => store.discardDraft()}>
              <Text>Discard</Text>
            </TouchableOpacity>
          </>
        )
      }

    </View>
  )
})

const styles = StyleSheet.create({
  container: {
    marginBottom: 10,
    flexDirection: "row",
    alignItems: "center",
    gap: 4,
  },
  nameTitle: {
    fontSize: 16,
    fontWeight: "bold",
    width: 80,
  },
  nameInputSurround: {
    flexDirection: "row",
    alignItems: "center",
    maxWidth: 320,
    width: "100%",
    gap: 4,
  },
  nameInput: {
    flex: 1,
    borderWidth: 1,
    borderColor: "gray",
    borderRadius: 5,
    padding: 5,
  },
  nameDisplay: {
    maxWidth: 320,
    width: "100%",
    borderWidth: 1,
    borderColor: "rgba(0,0,0,0)",
    backgroundColor: "rgba(0,0,0,0.05)",
    borderRadius: 5,
    padding: 5,
  },
  nameSaveButton: {
    borderColor: "rgba(0,0,0,0)",
    borderWidth: 1,
    backgroundColor: "#219653",
    borderRadius: 5,
    padding: 5,
  },
  nameSaveButtonDisabled: {
    backgroundColor: "gray",
  },
  nameSaveButtonText: {
    color: "white",
  }
})
