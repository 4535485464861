// // @ts-ignore
// window.global ||= window;

import React from 'react';
import ReactDOM from 'react-dom';
import {BrowserRouter as Router} from "react-router-dom";
// @ts-ignore -- leaving react 17 types as FunctionComponent now omits children... :-/
import { createRoot } from 'react-dom/client';


// import reportWebVitals from './reportWebVitals';
import 'rsuite/lib/styles/index.less';
import "./theme/variables.css";
// import "./theme/mk-variables.css";
import "./theme/base-theme.less";

import App from "./App";

import {Amplify} from "aws-amplify";
import {DomainContextProvider} from "./helpers/DomainContext";
// import awsExports from "./aws-exports";

Amplify.configure({
  "aws_project_region": import.meta.env.VITE_AWS_REGION,
  "aws_cognito_identity_pool_id": import.meta.env.VITE_AWS_IDENTITY_POOL_ID,
  "aws_cognito_region": import.meta.env.VITE_AWS_COGNITO_REGION,
  "aws_user_pools_id": import.meta.env.VITE_AWS_USER_POOLS_ID,
  "aws_user_pools_web_client_id": import.meta.env.VITE_AWS_USER_POOLS_WEB_CLIENT_ID,
  "oauth": {}
});


function Index() {
  return (
      <Router>
        <DomainContextProvider>
          <App/>
        </DomainContextProvider>
      </Router>
  )
  
}

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<Index />);

// ReactDOM.render(<Index/>, document.getElementById('root'));
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
