import React from 'react';
import cn from "classnames";
import {Icon} from "rsuite";

const TYPES: {label: string, type: GymTypeType}[] = [
  {label: "Online PT or group or PTs", type: "PT"},
  {label: "Gym, or in-person PT", type: "GYM"},
]

type OptionSelectorProps< K extends keyof GymRegistrationValues> =
  Pick<GymRegistrationPageProps, "updateValue" | "onNext"> &
  {
    options: {label: string, value: GymRegistrationValues[K]}[];
    description: string;
    field: K;
    updateValue(key: K): (val: GymRegistrationValues[K]) => void;
  }

export const OptionSelector = <K extends keyof GymRegistrationValues>({options, updateValue, description, onNext, field}: OptionSelectorProps<K>) => {
  
  const onSelect = (val: GymRegistrationValues[K]) => {
    updateValue(field)(val);
    onNext?.();
  }
  
  return (
    <div>
      <p style={{fontSize: "1rem", margin: "3rem 0 2rem"}}>{description}</p>
      {options.map(({label, value}, i) => (<OptionButton zIndex={TYPES.length - i} key={label} label={label} value={value} onClick={onSelect} />))}
    </div>
  );
};

type GymTypeType = GymRegistrationValues["type"]


type OptionButtonProps<K extends keyof GymRegistrationValues> = {label: string, value: GymRegistrationValues[K], zIndex?: number, onClick: (value: GymRegistrationValues[K]) => void};
export const OptionButton = <K extends keyof GymRegistrationValues, >({label, value, onClick, zIndex}: OptionButtonProps<K>) => {
  
  return (
    <div className={cn("neu", "dark")} style={{padding: "1.5rem", cursor: "pointer", zIndex, display: "flex", alignItems: "center", justifyContent: "space-between"}} onClick={() => onClick(value)}>
      <div style={{fontSize: "1rem"}}>{label}</div>
      <div style={{width: "1.6rem", height: "1.6rem", borderRadius: "0.8rem", backgroundColor: "var(--off-white)", display: "flex", alignItems: "center", justifyContent: "center"}}>
        <Icon icon={"arrow-right-line"} style={{color: "var(--brand-primary)"}} size={"lg"} />
      </div>
    </div>
  )
  
}
