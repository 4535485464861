import React from 'react';
import cn from "classnames";
import s from "./WholeScreenLoading.module.scss"
import ReactDOM from 'react-dom';


interface IOwnProps {
  loading: boolean;
  loadingText?: string;
  onTooLongPress?(): void;
  fadeInDuration?: number;
  displayDuration?: number;
  backgroundColor?: string;
  color?: string;
}

const DISPLAY_DURATION = 500;
const FADE_DURATION = 800;

export const WholeScreenLoading: React.FC<IOwnProps> = (
  {
    loading,
    children,
    loadingText = "Loading",
    onTooLongPress,
    fadeInDuration = FADE_DURATION,
    displayDuration = DISPLAY_DURATION,
    backgroundColor,
    color
  }) => {
  const [open, setOpen] = React.useState(loading);
  const [visible, setVisible] = React.useState(loading);
  const openTimeoutRef = React.useRef<number>();
  const visibleTimeoutRef = React.useRef<number>();
  
  const container = document.getElementById("root");
  
  React.useEffect(() => {
    if (openTimeoutRef.current) {
      clearTimeout(openTimeoutRef.current)
    }
    if (visibleTimeoutRef.current) {
      clearTimeout(visibleTimeoutRef.current)
    }
    if (loading) {
      setOpen(true);
      setVisible(true);
    } else {
      visibleTimeoutRef.current = window.setTimeout(() => {
        setVisible(false);
      }, displayDuration);
      openTimeoutRef.current = window.setTimeout(() => {
        setOpen(false);
      }, displayDuration + fadeInDuration);
    }
  }, [loading])
  
  return ReactDOM.createPortal(
    <div className={cn(s.surround, open && s.open, visible && s.visible)}
         style={{transition: `opacity ${fadeInDuration}ms ease-in-out`, backgroundColor}}>
      <div className={cn(s.content)}>
        {children ?? <div className={cn(s.loadingText)} style={{color}}>{loadingText}</div>}
        {
          open && (
            <div>
              <div className={cn(s.loader)}>
                <svg viewBox="0 0 80 80">
                  <circle id="test" cx="40" cy="40" r="32"></circle>
                </svg>
              </div>
              
              <div className={cn(s.loader, s.triangle)}>
                <svg viewBox="0 0 86 80">
                  <polygon points="43 8 79 72 7 72"></polygon>
                </svg>
              </div>
              
              <div className={cn(s.loader)}>
                <svg viewBox="0 0 80 80">
                  <rect x="8" y="8" width="64" height="64"></rect>
                </svg>
              </div>
            </div>
          )
        }
        {/*{*/}
        {/*  !!onTooLongPress ? (*/}
        {/*    <div onClick={onTooLongPress} className={cn(s.tooLong)}>This is taking a while - click here to hide the*/}
        {/*      loader</div>*/}
        {/*  ) : <div className={cn(s.tooLong)}/>*/}
        {/*}*/}
      </div>
    </div>
    , container!);
};
