import React, { PropsWithChildren } from "react";
import { gql, useQuery } from "@apollo/client";
import { RiMailFill } from "react-icons/ri";
import { Link } from "react-router-dom";

export const CLIENT_CONTACT = gql`
  query ClientContact($id: ID!) {
    clientContact(id: $id) {
      name
      email
    }
  }
`;
interface IOwnProps {
  id: string;
  firstName?: string;
  showLoading?: boolean;
  link?: string;
}

export const useClientContact = (id: string) =>
  useQuery<{ clientContact: { name: string; email: string } }>(CLIENT_CONTACT, {
    variables: { id },
    skip: !id,
  });

export const ClientContact = ({
  id,
  showLoading = true,
  firstName: firstNameFallback,
  link,
}: IOwnProps) => {
  const { error, loading, data } = useClientContact(id);

  const shouldShowFallback = !!firstNameFallback && !data?.clientContact?.name;

  return (
    <div style={{ display: "flex", alignItems: "center", gap: "0.5rem" }}>
      {shouldShowFallback && firstNameFallback}{" "}
      {loading && showLoading
        ? "Loading"
        : <MaybeLink link={link}>{data?.clientContact.name}</MaybeLink> ?? ""}
      {data?.clientContact?.email && (
        <a href={`mailto:${data?.clientContact.email}`}>
          <div
            role="button"
            tabIndex={0}
            style={{
              display: "inline-flex",
              padding: "0.25rem",
              borderRadius: 8,
              backgroundColor: "var(--brand-primary)",
              cursor: "pointer",
            }}
            title={`Email ${data?.clientContact.email}`}
          >
            <RiMailFill color="white" />
          </div>
        </a>
      )}
      {/* <pre style={{ fontSize: 8 }}> */}
      {/* {JSON.stringify({ error, loading, data }, null, 2)} */}
      {/* </pre> */}
    </div>
  );
};

const MaybeLink = ({
  link,
  children,
}: PropsWithChildren<{ link?: string }>) => {
  if (!link) return <>{children}</>;
  return <Link to={link}>{children}</Link>;
};
