import React from 'react';
import {OptionSelector} from "./OptionSelector";

type GymTypeType = GymRegistrationValues["type"]
const TYPES: { label: string, value: GymTypeType }[] = [
  {label: "Online PT or group of PTs", value: "PT"},
  {label: "Single Gym, or in-person PT", value: "GYM"},
  {label: "Group of Gyms", value: "GYM_GROUP"},
]

export const GymType = ({updateValue, onNext}: GymRegistrationPageProps) => {
  
  return <OptionSelector updateValue={updateValue} options={TYPES}
                         description={"First, please tell us a little about what kind of business you are:"}
                         field={"type"} onNext={onNext}/>
  
};

