import { gql } from "@apollo/client";

export const PACKAGE = gql`
  query Package($id: ID!) {
    package(id: $id)
  }
`;

export const PRODUCT_PACKAGES = gql`
  query ProductPackages($productId: String!) {
    productPackages(productId: $productId) {
      id
      status
      priceId
      discountPeriods
      discountAmount
      name
      status
    }
  }
  `;


export const CREATE_PACKAGE = gql`
  mutation CreatePackage($priceId: String!, $productId: String!, $name: String!, $discountPeriods: Float, $discountAmount: Float){
    createPackage(
      priceId: $priceId
      productId: $productId
      name: $name
      discountPeriods: $discountPeriods
      discountAmount: $discountAmount
    ) {
      id
      priceId
      productId
      name
      discountPeriods
      discountAmount
    }
  }
  
  `


export const UPDATE_PACKAGE = gql`
  mutation UpdatePackage($id: ID!, $data: PackageUpdateInput!){
    updatePackage(
      id: $id
      data: $data
    ) {
      id
      priceId
      productId
      name
      discountPeriods
      discountAmount
    }
  }
  
  `
