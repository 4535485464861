import React from 'react';
import {useParams} from "react-router";
import "@fontsource/rock-salt"
import cn from "classnames";
import s from "../Registration.module.scss";
import {Button, Checkbox, Icon, Input} from "rsuite";
import {useWorking} from "../../../../hooks/useWorking";
import validator from "validator";
import {useScreenLoading} from "../../../../context/ScreenLoadingContext";
import {useNotification} from "../../../../hooks/useNotification";
import {BrandArea} from "./components/BrandArea";
import {usePackage} from "../../../../hooks/usePackage";
import ReactGA from "react-ga4";
import {Link} from "react-router-dom";
import {USER_ROUTES} from "../../../../config/ROUTES";

interface IOwnProps {
  onSubmit(details: {firstName: string; lastName: string; email: string}): Promise<any>;
  packageStatus?: string;
  gym?: IGym;
}

type RegistrationValues = {
  firstName: string;
  lastName: string;
  // password: string;
  email: string;
  termsAccepted: boolean;
}

const sampleEmail = import.meta.env.DEV ? "leo.ijebor+_SEED_@googlemail.com" : "";
const seed = Math.floor(Math.random() * 10000000);


const DEFAULTS = import.meta.env.DEV ? {
  firstName: `LeoGroup${seed}`,
  lastName: "Leo",
  email: sampleEmail.replace("_SEED_", String(seed)),
  termsAccepted: true,
} : {}

export const StartPackageRegistration = ({onSubmit, packageStatus, gym}: IOwnProps) => {
  const [values, setValues] = React.useState<Partial<RegistrationValues>>(DEFAULTS);
  const [tested, setTested] = React.useState(false);
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const {working, setWorking} = useWorking();
  
  const {addElement, removeElement} = useScreenLoading();
  const notify = useNotification();
  
  
  React.useEffect(() => {
    ReactGA.event({
      label: "Package registration started",
      action: "Begin package registration",
      category: "customer-revenue",
      value: 1
    });
  }, []);
  
  
  const update = <T extends keyof GymRegistrationValues, >(key: T) => (value: GymRegistrationValues[T]) => {
    setValues(prev => ({
      ...prev,
      [key]: value,
    }));
    if (tested) {
      validate();
    }
  }

  
  const validate = () => {
    const newErrors: Record<string, string> = {};
    
    
    if ((values.firstName ?? "").length < 2) {
      newErrors.firstName = "First name must contain at least two characters";
    }
    if ((values.lastName ?? "").length < 2) {
      newErrors.lastName = "Last name must contain at least two characters";
    }
    if (!validator.isEmail(values.email ?? "dd")) {
      newErrors.email = "Invalid email address";
    }
    if (!values.termsAccepted) {
      newErrors.termsAccepted = "You must agree to the terms to continue";
    }
    setErrors(newErrors);
    return !Object.keys(newErrors).length;
  }
  
  const submit = () => {
    if (!validate()) {
      setTested(true);
      return;
    }
    
    const LOADING_KEY = "CREATING_ACCOUNT";
    setWorking(true);
    addElement({id: LOADING_KEY, loadingText: "Building your shiny new account"});
    
    const {
      firstName,
      lastName,
      email,
    } = values;
    
    
    notify(
      {
        before: "Hold tight - we're creating your account",
        successMessage: "Done! Let's get started.",
        errorMessage: "We were unable to load this package",
        showError: true,
      },
      onSubmit({firstName, lastName, email} as any)
    )
      .catch((error) => {
        console.error(error);
        // history.push("/");
      })
      .finally(() => {
        setWorking(false);
        removeElement(LOADING_KEY);
      })
  }
  
  
  return (
    <main className={cn(s.main)}>
      
      <section className={cn(s.light)}>
        {
          !!packageStatus && packageStatus !== "ACTIVE" && (
            <article>
              <h2>This offer has not been found, or has expired</h2>
              <div>Please try a different link{!!gym?.group?.emailReplyAddress &&
              <span>, or reach out to <b><a href={`mailto:${gym?.group?.emailReplyAddress}`} target={"_blank"}
                                         rel={"noreferrer"}>{gym?.group?.emailReplyAddress}</a></b></span>}</div>
            </article>
          )
        }
        
        {
          packageStatus === "ACTIVE" && (
            <article>
              <h1>Sign up</h1>
              {/*<pre style={{fontSize: 12}}>{JSON.stringify({error, loading, data}, null, 2)}</pre>*/}
              <p className={cn(s.intro)}>You're nearly there - let's get started!</p>
              <div className={cn(s.foldable)}>
                <InputRow disabled={working} label={"First Name"} value={values.firstName}
                          onChange={update("firstName")}
                          error={errors.firstName}/>
                <InputRow disabled={working} label={"Last Name"} value={values.lastName} onChange={update("lastName")}
                          error={errors.lastName}/>
              </div>
              <InputRow disabled={working} label={"E-mail"} value={values.email} onChange={update("email")}
                        error={errors.email}/>
              <Checkbox className={cn(s.termsCheckbox)} checked={!!values.termsAccepted}
                        onChange={(_, checked) => update("termsAccepted")(checked)}>
                I accept the <a href={"https://vivifit.co/terms-conditions"} target={"_blank"}
                                rel={"noreferrer"}><strong>Terms
                & Conditions </strong><Icon style={{color: "var(--grey-3)"}} icon={"external-link"}/> </a> and <a
                href={"https://vivifit.co/privacy"} target={"_blank"} rel={"noreferrer"}><strong>Privacy
                policy </strong><Icon style={{color: "var(--grey-3)"}} icon={"external-link"}/> </a>
              </Checkbox>
              {
                errors.termsAccepted &&
                <div style={{color: "var(--brand-deny)"}}>You must accept the terms to continue</div>
              }
              <Button disabled={working} appearance={"primary"} onClick={submit}
                      style={{float: "right", cursor: "pointer"}}>Save</Button>
              {/*<WholeScreenLoading loading={working} loadingText={"Building your shiny new gym"} />*/}
              <br/>
              <br/>
              <p className={cn(s.subtle)}>Already have an account? <Link style={{fontWeight: "bold", textDecorationLine: "underline"}} to={USER_ROUTES.billing.blockBookings.url}>Sign in</Link></p>
              
            </article>
          )
        }
      </section>
      <BrandArea gymId={gym?.id}/>
    
    </main>
  );
};


interface IInputRowProps {
  label: string;
  value?: string;
  type?: "text" | "password";
  error?: string;
  disabled?: boolean;
  onChange(value: string): void;
}

const InputRow = ({label, value, onChange, type = "text", error, disabled}: IInputRowProps) => {
  return (
    <>
      <div className={cn(s.inputRowSurround)}>
        <label><b>{label}</b></label>
        <div className={cn(s.input)}>
          <Input disabled={disabled} type={type} value={value} onChange={onChange}/>
          {!!error && <div style={{color: "var(--brand-deny)"}}>{error}</div>}
        </div>
      </div>
    
    </>
  )
}
