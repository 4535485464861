import React, { FC } from "react";
import cn from "classnames";
import s from "./ColoredButton.module.css";
import { IconNames } from "rsuite/lib/Icon";
import { Icon } from "rsuite";

const GRADIENTS = {
  good: "var(--gradient-confirm)",
  bad: "var(--gradient-deny)",
  warn: "var(--gradient-warn)",
  neutral: "var(--gradient-brand)",
  disabled: "var(--gradient-grey)",
  subtle: "var(--gradient-white)",
  plain: "unset",
  alternate: "var(--gradient-alt)",
};
const COLORS = {
  good: "var(--off-white)",
  bad: "var(--off-white)",
  neutral: "var(--off-white)",
  warn: "var(--off-white)",
  disabled: "var(--grey-4)",
  subtle: "var(--grey-1)",
  plain: "var(--brand-primary)",
  alternate: "var(--grey-5)",
};

export type ColoredButtonProps = {
  color?: keyof typeof COLORS;
  // "neutral" | "good" | "bad" | "warn" | "subtle" | "alternate";
  label: string;
  width?: React.ReactText;
  minWidth?: React.ReactText;
  statusLabel?: string;
  icon?: IconNames;
  disabled?: boolean;
  style?: React.CSSProperties;
  buttonStyle?: React.CSSProperties;
  padding?: React.ReactText;
  onDark?: boolean;
  onClick?(e?: React.MouseEvent<HTMLDivElement, MouseEvent>): void;
};

const ColoredButton: FC<ColoredButtonProps> = ({
  color = "neutral",
  label,
  statusLabel,
  disabled,
  onClick,
  icon,
  style,
  width,
  minWidth,
  padding,
  onDark = true,
  buttonStyle,
}) => {
  return (
    <div style={{ display: "flex", alignItems: "center", ...style }}>
      <div
        onClick={(e) => !disabled && onClick?.(e)}
        tabIndex={0}
        role="button"
        className={cn("neu", "low", typeof onClick === "function" && "hover")}
        style={{
          backgroundImage: disabled ? GRADIENTS.disabled : GRADIENTS[color],
          color: disabled ? COLORS.disabled : COLORS[color],
          width,
          minWidth,
          marginRight: "0.5rem",
          marginBottom: 0,
          display: "flex",
          alignItems: "center",
          boxShadow: color === "plain" ? "unset" : onDark
            ? "3px 3px 6px rgba(0,0,0,0.1), -3px -3px 6px rgba(0,0,0,0.2)"
            : "3px 3px 6px #ffffff, -3px -3px 6px #d9d9d9",
          justifyContent: "center",
          padding,
          ...buttonStyle,
        }}
      >
        {!!icon && (
          <Icon
            icon={icon}
            style={{ marginRight: !!label ? "0.5rem" : undefined }}
          />
        )}
        {label}
      </div>
      {statusLabel && (
        <span
          onClick={() => !disabled && onClick?.()}
          className={cn(typeof onClick === "function" && s.link)}
        >
          {statusLabel}
        </span>
      )}
    </div>
  );
};

export default ColoredButton;
