import cn from 'classnames';
import s from './TrainingPlanGrid.module.scss';
import { Phase, Week } from '@vivifit/fitdo-utils';
import { observer } from 'mobx-react-lite';

export const TrainingPlanGrid = observer(({ phase }: { phase: Phase | undefined }) => {

  const maxDaysPerWeek = phase?.maxDaysPerWeek || 0;
  return (
    <div className={cn(s.surround)}>
      {
        phase?.weeks.map((week) => (
          <TrainingPlanWeek key={week.weekNo} week={week} maxDaysPerWeek={maxDaysPerWeek} />
        ))
      }
    </div>
  );
});

export const TrainingPlanWeek = observer(({ week, maxDaysPerWeek: maxDaysPerWeekIn }: { week: Week, maxDaysPerWeek: number }) => {
  const maxDaysPerWeek = maxDaysPerWeekIn || 7;
  return (
    <div key={week.weekNo} className={cn(s.week)}>
      <div className={cn(s.weekHeader)}>Week {week.weekNo + 1}</div>
      {
        Array.from({ length: maxDaysPerWeek }).map((_, i) => {
          const day = week.days?.[i];

          return (
            <Day key={i} day={day} />
          )
        })
      }
    </div>
  );
});

const Day = observer(({ day }: { day: Week['days'][number] | undefined }) => {
  return (
    <div className={cn(s.day)}>
      {/* <div className={cn(s.dayHeader)}>Day {dayNo + 1}</div> */}
      {
        day?.workouts.map((workout) => (
          <div
            className={cn(s.workout)}
            key={workout.id}
          >
            {workout.name}
          </div>
        ))
      }
    </div>
  );
});
