import React from 'react';
import {useQuery} from "@apollo/client";
import cn from "classnames";
import s from "./Plans.module.css"

import {GYM_SUBSCRIPTION_OPTIONS} from "../../../../../apollo/accountOperations";
import ErrorDisplay from '../../../../../components/ErrorDisplay/ErrorDisplay';
import {StripeProductWithPrices} from '../../../../../types/ISubscription';
import {Toggle} from "rsuite";
import {getCurrencySymbol} from "../../../../../constants/currencies";
import ColoredButton from "../../../../../components/ColoredButton/ColoredButton";


export const Plans = ({updateValue, values, onNext}: GymRegistrationPageProps) => {
  // const [selectedPlanId, setSelectedPlanId] = React.useState<string>();
  const [suggestedPlanId, setSuggestedPlanId] = React.useState<string>();
  
  const [annual, setAnnual] = React.useState(true);
  
  const {
    error,
    loading,
    data,
    refetch
  } = useQuery<{ gymSubscriptionOptions: StripeProductWithPrices[] }>(GYM_SUBSCRIPTION_OPTIONS);
  
  const {selectedPlanId} = values;
  
  
  const recurrence = annual ? "year" : "month";
  
  const options = React.useMemo(() => {
    return data?.gymSubscriptionOptions
      .map(option => ({
        ...option,
        prices: option.prices.filter(p => p.recurrence === recurrence).slice(0, 1),
      }))
      .filter(option => !!option.prices.length)
      .sort((a, b) => (a.prices[0]?.amount ?? 0) - (b.prices[0]?.amount ?? 0))
    
  }, [data, annual]);
  
  const selectPlan = (id: "enterprise" | string) => {
    updateValue("selectedPlanId")(id);
  }
  
  React.useEffect(() => {
    const suggestedPlan = options?.find(option => (
      parseInt(option.metadata?.clientsIncluded ?? "0") >= (values.numClients ?? 0) &&
      parseInt(option.metadata?.trainersIncluded ?? "0") >= (values.numTrainers ?? 0)
    ));
    
    const newSuggestedPlanId = suggestedPlan?.id || "enterprise";
    selectPlan(newSuggestedPlanId);
    setSuggestedPlanId(newSuggestedPlanId);
  }, [values.type, values.numClients, values.numTrainers, data]);
  
  
  
  return (
    <div >
      <ErrorDisplay error={error} loading={loading} refetch={refetch}/>
      <div className={cn(s.toggleSurround)}>
        <span style={{color: "var(--grey-4)"}}>Billed&nbsp;</span>
        <span style={{fontWeight: !annual ? "bold" : undefined, color: !annual ? undefined : "var(--grey-4)"}}>
          Monthly&nbsp;
        </span>
        <Toggle checked={annual} onChange={setAnnual}/>
        <span style={{
          fontWeight: annual ? "bold" : undefined,
          color: annual ? undefined : "var(--grey-4)"
        }}>&nbsp;Annually
        </span>
      </div>
      
      <div className={cn(s.productsSurround)}>
        {
          options?.map((option, i) => (
            <div key={option.id} onClick={() => selectPlan(option.id)} className={cn("neu", option.id === selectedPlanId && "dark", s.productSurround)}>
              <div className={cn(s.productTitle)}>
                {option.productName}
              </div>

              <div className={cn(s.productDescription)}>
                {option.description}
              </div>
              <div>
                <span className={cn(s.price)}>{getCurrencySymbol(option.prices[0]?.currency)}{(option.prices[0]?.amount) / 100 / (annual ? 12 : 1)}</span>
                <span> / month</span>
              </div>
              <div className={cn(s.note)}>{annual ? "Billed annually" : "Cancel anytime"}</div>
              <PlanFeatures product={option} />
              {option.id === suggestedPlanId && (
                <div className={cn(s.recommended)}>Recommended for you</div>
              )}
              
              
            </div>
          ))
        }
        <div onClick={() => selectPlan("enterprise")} className={cn("neu", selectedPlanId  === "enterprise" && "dark", s.productSurround)}>
          <div className={cn(s.productTitle)}>Enterprise</div>
    
          <div className={cn(s.productDescription)}>
            Custom pricing for larger operations
          </div>
          <div>
            <span className={cn(s.price)}>Talk to us</span>
          </div>
          <div className={cn(s.note)}>For pricing</div>
          <ul className={cn(s.featureList)}>
            <li><strong>Unlimited possibilities</strong></li>
            {/*<li>Arrange a call and a</li>*/}
          </ul>
  
  
        </div>
      </div>
      <div style={{position: "fixed", bottom: "1rem", right: "1rem"}}>
        <ColoredButton disabled={!selectedPlanId} color={"good"} label={"Next"} onClick={onNext}/>
      </div>
      {/*<pre style={{fontSize: 12}}>{JSON.stringify({error, loading, data}, null, 2)}</pre>*/}
    </div>
  );
};

const PlanFeatures = ({product}: {product: StripeProductWithPrices}) => {
  const {metadata} = product;
  if(!Object.keys(metadata || {}).length){
    return null;
  }
  
  const draw = (key: keyof StripeProductWithPrices["metadata"], text: string) => typeof metadata[key] !== "undefined" && (<li><strong>{metadata[key]}</strong> {text}</li>)
  const drawBoolean = (key: keyof StripeProductWithPrices["metadata"], text: string, subText?: string) => (metadata[key] === "true") && (<li><strong>{text}</strong>{subText}</li>)
  
  return (
    <ul className={cn(s.featureList)}>
      {draw("trainersIncluded", "Trainers")}
      {draw("clientsIncluded", "Clients")}
      {draw("videoStorage", "Video storage")}
      {drawBoolean("customBranding", "Custom Branding")}
      {drawBoolean("customBranding", "Custom App store listing", " for a one-off fee")}
    </ul>
  )
}

