import React from 'react';
import {Route, useRouteMatch} from "react-router";
import {gql, useMutation} from "@apollo/client";
import {Icon, Notification} from "rsuite";
import { Link } from 'react-router-dom';

interface IOwnProps {

}

const mutation = gql`
  mutation RespondToTrainerAssignment($response: String!, $userHash: String!, $trainerHash: String!) {
    respondToTrainerAssignment(userHash: $userHash, trainerHash: $trainerHash, response: $response){
      success
      message
    }
  }
`
const TrainerAssignmentResponse = (props: IOwnProps) => {
  const [status, setStatus] = React.useState<"WORKING" | "SUCCESS" | "ERROR">("WORKING");
  const [mutate] = useMutation(mutation)
  
  const {params: {response, userHash, trainerHash}} = useRouteMatch<{response: string, userHash: string, trainerHash: string}>();
  
  React.useEffect(() => {
    mutate({variables: {response, userHash, trainerHash}})
      .then((response) => {
        if(response.data?.respondToTrainerAssignment?.success){
          Notification.success({title: "Done!", description: response.data?.message});
          setStatus("SUCCESS");
        } else {
          Notification.error({title: "An error occurred", description: response.data?.message});
          setStatus("ERROR");
        }
      }).catch((error) => {
      Notification.error({title: "An error occurred", description: error?.message});
      setStatus("ERROR");
    })
  }, [response, userHash, trainerHash])
  
  return (
    <div style={{backgroundColor: "white"}}>
            <h1>Result</h1>
      <div style={{marginTop: "2rem", marginBottom: "2rem"}}>
        {
          status === "ERROR" && <div style={{display: "flex", alignItems: "center"}}>
              <Icon icon={"warning"} size={"5x"} style={{color: "var(--brand-deny)", marginRight: 20}}/>
              <span>Access rejected or request not found</span>
          </div>
        }
        {
          status === "SUCCESS" && <div style={{display: "flex", alignItems: "center"}}>
              <Icon icon={"check-circle"} size={"5x"} style={{color: "var(--brand-confirm)", marginRight: 20}}/>
              <span>Done! Access has been {response}ed as requested</span>
          </div>
        }
      </div>
      {
        status !== "WORKING" && <Link to={"/"} >Click here to go to the homepage</Link>
      }
    </div>
  );
};

export default TrainerAssignmentResponse;
