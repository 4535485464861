import React, { FC } from 'react';

import { generatePath } from "react-router";

interface IOwnProps {
  match: any;
  mobilePath: string;
  path: string;
}

const DeepLinkRedirect: FC<IOwnProps> = ({match, mobilePath, path}) => {
  
  React.useEffect(() => {
    //@ts-ignore
    // window.location = `${import.meta.env.VITE_APP_URL_SCHEMA}${generatePath(mobilePath, match?.params)}`;
    setTimeout(() => {
      //@ts-ignore
      window.location = generatePath(`${path}`, match?.params);
      // window.location = "http://google.com";
    // }, 1000)
    }, 100)
  }, []);
  
  return (
    <div style={{backgroundColor: "white"}}>
        Checking platform
      {/*<pre>{JSON.stringify({match, path, mobilePath}, null, 2)}</pre>*/}
    </div>
  );
};

export default DeepLinkRedirect;
