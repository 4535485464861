import * as Sentry from "@sentry/react";
import useProfile from "./useProfile";
import {SeverityLevel} from "@sentry/react";


const useSentry = () => {
  const {userId} = useProfile();
  
  const log = sendSentry("log", userId);
  const error = sendSentry("error", userId);
  const warn = sendSentry("warning", userId);
  
  return {log, error, warn}
}

const sendSentry = (severity: SeverityLevel, userId?: string) => (message: string, extra?: any) => {
  Sentry.captureEvent({
    environment: import.meta.env.VITE_STAGE,
    message: message,
    level: severity,
    extra: {extra: JSON.stringify(extra, null, 2)},
    user: userId ? {username: userId} : undefined,
  });
}

export default useSentry;
