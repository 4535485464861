import React, {FC} from 'react';
import {ApolloError} from "@apollo/client";
import {Link} from 'react-router-dom';
import useSentry from "../../hooks/useSentry";
import Loading from "../Loading";
import { PlaceholderCard } from '@vivifit/react-components';


interface IOwnProps {
  error?: ApolloError;
  loading?: boolean;
  fullScreen?: boolean;
  placeholder?: "card";
  refetch?(): void;
}

const ErrorDisplay: FC<IOwnProps> = ({error, refetch, loading, fullScreen = true, placeholder}) => {
  const sentry = useSentry();
  
    React.useEffect(() => {
    if(error){
      sentry.error(error.message, {extra: error.extraInfo});
    }
  }, [error])
  
  
  
  
  const retry = () => refetch?.()
  
  if(loading){
    if(placeholder === "card"){
      return (
        <PlaceholderCard />
      )
    }
    return <div style={{position: "relative", minHeight: 80, minWidth: 80}}><Loading fullScreen={fullScreen} /></div>
  }
  
  if(!error) return null;
  
  return (
    <div>
      An error occurred whilst trying to load data.
      {
        typeof refetch === "function" &&
        <strong><Link to={"#"} onClick={retry}> Click here to retry</Link></strong>
      }
    </div>
  );
};

export default ErrorDisplay;
