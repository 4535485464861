import React from 'react';
import jwt from "jsonwebtoken";

interface IGymContext {
  gymToken: string | null;
  gymId?: string;
  getGymId(): string;
  
  setGymToken(token: string | null): void;
}

const getGymTokenFromStorage = () => window.localStorage.getItem("@gym:token");
const getGymId = () => (jwt.decode(window.localStorage.getItem("@gym:token") ?? "") as any)?.gymId ?? "";


export const GymContext = React.createContext<IGymContext>(
  {
    gymToken: getGymTokenFromStorage() ?? "",
    gymId: getGymId(),
    getGymId,
    setGymToken: (token: string) => {
      console.log("Not ready to set gymToken", token)
    }
  });

export const useGymContext = () => React.useContext(GymContext);
