import React from 'react';
import { ApolloProvider } from '@apollo/client';
import { Authenticator } from "@aws-amplify/ui-react";
import '@aws-amplify/ui-react/styles.css';
import "@fontsource/rubik";


import './App.css';
import MainNavigator from "./navigation/MainNavigator";
import client from "./apollo/client";
import { GymContext } from "./context/GymContext";
import jwt from "jsonwebtoken";
import { Route, Switch } from "react-router-dom";
import { configureSentry } from "./config/Sentry";
import { ProfileContextProvider } from "./context/ProfileContext";
import { ROUTES } from "./config/ROUTES";
import CompleteRegistration from "./scenes/Gym/Account/CompleteRegistration";
import { SelfRegister } from "./scenes/Common/Registration/SelfRegister";
import DeepLinkRedirect from "./components/DeepLinkRedirect";
import { GymSelfRegister } from "./scenes/Common/Registration/GymSelfRegister_DEPR/GymSelfRegister";
import TrainerAssignmentResponse from "./containers/TrainerAssignment/TrainerAssignmentResponse";
import { HelpContextProvider } from "./context/HelpContext";
import { ConfigProvider } from "./context/ConfigContext";
import { usePageTracking } from "./hooks/usePageTracking";
import { GymJoin } from "./scenes/Common/Registration/GymJoin/GymJoin";
import { ScreenLoadingProvider } from "./context/ScreenLoadingContext";
import { CustomAuthenticator } from "./scenes/Common/Registration/CustomAuthenticator";
import { FinishPackageRegistration } from "./scenes/Common/Registration/JoinWithPackage/FinishPackageRegistration";
import {
  StartSubscriptionPackageRegistration
} from "./scenes/Common/Registration/JoinWithPackage/StartSubscriptionPackageRegistration";
import {
  StartBlockBookingPackageRegistration
} from "./scenes/Common/Registration/JoinWithPackage/StartBlockBookingPackageRegistration";
import { SearchContextProvider } from "../../../packages/react-components/src/context/SearchContext";
import { SettingsProvider } from "./hooks/SettingsContext";


if (import.meta.env.DEV) {
  console.log({ env: import.meta.env })
}
configureSentry();


function App() {
  const [gymToken, _setGymToken] = React.useState<string | null>(window.localStorage.getItem("@gym:token"));
  const [gymId, setGymId] = React.useState<string>((jwt.decode(gymToken ?? "") as any)?.gymId);



  usePageTracking();

  const setGymToken = (token: string | null) => {
    if (token) {
      window.localStorage.setItem("@gym:token", token);
      setGymId((jwt.decode(token ?? "") as any)?.gymId)
    } else {
      window.localStorage.removeItem("@gym:token");
    }

    _setGymToken(token)
    client.resetStore();
  }


  const getGymId = () => (jwt.decode(window.localStorage.getItem("@gym:token") ?? "") as any)?.gymId;

  return (
    <GymContext.Provider
      value={{ gymToken, setGymToken, getGymId, gymId, }}>
      <SearchContextProvider>
        <ApolloProvider client={client}>
          <ConfigProvider>
            <HelpContextProvider>
              <ScreenLoadingProvider>
                <Switch>
                  {/*@ts-ignore */}
                  <Route path="/.well-known/apple-app-site-association" onEnter={() => window.location.reload()} />
                  {/*@ts-ignore */}
                  <Route path="/apple-app-site-association" onEnter={() => window.location.reload()} />

                  <Route path="/trainer-assignment/:response/:userHash/:trainerHash"
                    component={TrainerAssignmentResponse} />

                  <Route path={ROUTES.registration.register.url} exact component={CompleteRegistration} />
                  <Route path={ROUTES.registration.join.urls} exact component={SelfRegister} />
                  <Route path={ROUTES.registration.joinWithBlockBooking.start.url} exact
                    component={StartBlockBookingPackageRegistration} />
                  <Route path={ROUTES.registration.joinWithBlockBooking.finish.url} exact
                    component={FinishPackageRegistration} />
                  <Route path={ROUTES.registration.joinWithPackage.start.url} exact
                    component={StartSubscriptionPackageRegistration} />
                  <Route path={ROUTES.registration.joinWithPackage.finish.url} exact
                    component={FinishPackageRegistration} />
                  <Route path={"/gymJoinOld"} exact component={GymSelfRegister} />
                  <Route path={ROUTES.registration.gymJoin.urls} exact>
                    <GymJoin type={"FULL"} />
                  </Route>
                  <Route path={ROUTES.registration.recipeJoin.urls} exact>
                    <GymJoin type={"RECIPE"} />
                  </Route>
                  <Route path="/registration">
                    <Switch>
                      <Route path={"/registration/invitation/:email"} exact children={({ match }) => (
                        <DeepLinkRedirect
                          match={match}
                          mobilePath={"/"}
                          path={"/"}
                        />
                      )}
                      />
                      <Route path={"/registration/invitation/:email/:userToken"} children={({ match }) => (
                        <DeepLinkRedirect
                          match={match}
                          mobilePath={"/registration/invitation/:email/:userToken"}
                          path={"/register/:email/:userToken"}
                        />
                      )}
                      />

                    </Switch>
                  </Route>
                  <Route path="/">
                    <Authenticator.Provider>
                      <CustomAuthenticator>
                        <ProfileContextProvider>
                          <SettingsProvider>
                            <MainNavigator />
                          </SettingsProvider>
                        </ProfileContextProvider>
                      </CustomAuthenticator>
                    </Authenticator.Provider>
                  </Route>

                  {/*@ts-ignore*/}
                  <Route path="/.well-known/apple-app-site-association" onEnter={() => window.location.reload()} />
                  {/*@ts-ignore*/}
                  <Route path="/apple-app-site-association" onEnter={() => window.location.reload()} />

                </Switch>
              </ScreenLoadingProvider>
            </HelpContextProvider>
          </ConfigProvider>
        </ApolloProvider>
      </SearchContextProvider>
    </GymContext.Provider>
  );
}


export default App;
