import React from 'react';
import {StartPackageRegistration} from "./StartPackageRegistration";
import axios from "axios";
import {apiUri} from "../../../../config/api";
import {useParams} from "react-router";

export const StartBlockBookingPackageRegistration = () => {
  
  const {gymId, priceId} = useParams<{ gymId: string; priceId: string; }>();
  
  
  const onSubmit = ({firstName, lastName, email}: { firstName: string; lastName: string; email: string }) => {
    return axios.post<{ checkoutSessionUrl: string }>(`${apiUri}/stripe/join-block/${gymId}/${priceId}`, {
      firstName,
      lastName,
      email,
      // password
    })
      .then(result => {
        if (result.data.checkoutSessionUrl) {
          window.location.href = result.data.checkoutSessionUrl;
        }
      })
  }
  
  return (
    <StartPackageRegistration onSubmit={onSubmit} packageStatus={"ACTIVE"} gym={{id: gymId} as any}/>
  );
};


