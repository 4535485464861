import React, {ChangeEvent, FC, HTMLAttributes, InputHTMLAttributes} from 'react';
import cn from "classnames";
import s from "./Input.module.css";
import {FaSearch} from "react-icons/all";


interface IOwnProps {
  containerStyle?: HTMLAttributes<HTMLDivElement>["style"];
  clearable?: boolean;
  onChange?(s: string): void;
  iconPosition?: "none" | "left" | "right";
}

const Input: FC<Omit<InputHTMLAttributes<HTMLInputElement>, "onChange"> & IOwnProps> = (
  {
    className,
    type,
    placeholder,
    containerStyle,
    onChange,
    clearable,
    iconPosition = "none",
    ...rest
  }
  ) => {
  
  return (
    <div
      style={containerStyle}
      className={cn(s.surround)}
    >
      <input type={type || "text"} className={cn(s.input, className)}
             placeholder={placeholder || "Enter value"}
             onChange={(e: ChangeEvent<HTMLInputElement>) => onChange?.(e.target.value)}
             {...rest}
      />
      <FaSearch className={cn(s.searchIcon, s[iconPosition])}/>
      {
        clearable && (
          <div style={{position: "absolute", top: 0, right: 0, backgroundColor: "red", height: 40, width: 40}}/>
        )
      }
    </div>
  )
};

export default Input;
