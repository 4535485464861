import {Notification} from "rsuite";
import useSentry from "./useSentry";

type NotifyOptions = { before?: string, successMessage?: string, successDescription?: string, errorMessage?: string, errorDescription?: string, showError?: boolean; suppressSuccess?: boolean}

const emptyPromise = () => {
  return new Promise((resolve, reject) => {
    resolve(true);
  })
};

export const useNotification = () => {
  const sentry = useSentry();
  
  return <T extends any>(options?: NotifyOptions, promise?: Promise<T>) => {
    const {before, successMessage = "Changes saved", errorMessage = "An error occurred whilst saving",successDescription, errorDescription, showError = true} = options ?? {};
    
    const onError = (error: any) => {
      console.log({error})
      sentry.error(error?.message || errorMessage, error);
      const details = !showError ? '' : `${error?.response?.data?.message || error?.message || "Unknown error"}`;
      
      
      Notification.error({title: errorMessage, description: `${errorDescription ?? ""} ${details}`});
      return error;
    };
    
    const onSuccess = (result: T) => {
      if(!options?.suppressSuccess){
        Notification.success({title: successMessage, description: successDescription});
      }
      return result;
    };
    if(promise){
      return promise
        .then(onSuccess)
        .catch(onError)
    }
    return emptyPromise();
  }
}

