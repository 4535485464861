import React from 'react';
import {StartPackageRegistration} from "./StartPackageRegistration";
import axios from "axios";
import {apiUri} from "../../../../config/api";
import {useParams} from "react-router";
import {usePackage} from "../../../../hooks/usePackage";

export const StartSubscriptionPackageRegistration = () => {
  
  const {packageId} = useParams<{ packageId: string }>();
  const {data} = usePackage(packageId);
  
  
  const onSubmit = ({firstName, lastName, email}: { firstName: string; lastName: string; email: string }) => {
    return axios.post<{ checkoutSessionUrl: string }>(`${apiUri}/stripe/join-package/${packageId}`, {
      firstName,
      lastName,
      email,
      // password
    })
      .then(result => {
        if (result.data.checkoutSessionUrl) {
          window.location.href = result.data.checkoutSessionUrl;
        }
      })
  }
  
  return (
    <StartPackageRegistration onSubmit={onSubmit} gym={data?.package?.gym} packageStatus={data?.package?.status}/>
  );
};
