import {useMutation, useQuery} from "@apollo/client";
import {CREATE_PACKAGE, PACKAGE, PRODUCT_PACKAGES, UPDATE_PACKAGE} from "../apollo/packageOperations";

export const usePackage = (id: string | undefined) => {
  return useQuery<{ package: IPackage }>(PACKAGE, {variables: {id}, skip: !id});
}

export const usePackages = (productId: string | undefined) => {
  return useQuery<{ productPackages: IPackage[] }>(PRODUCT_PACKAGES, {variables: {productId}, skip: !productId});
}


export const useCreatePackage = () => useMutation<{ createPackage: IPackage | null }, { priceId: string, productId: string; name: string; discountPeriods?: number; discountAmount?: number }>(CREATE_PACKAGE, {refetchQueries: ["ProductPackages"]});
export const useUpdatePackage = () => useMutation<{ updatePackage: IPackage | null }, { id: string, data: Partial<IPackage> }>(UPDATE_PACKAGE, {refetchQueries: ["ProductPackages"]});
