import React from 'react';
import {OptionSelector} from "./OptionSelector";

const OPTIONS: {label: string, value: number}[] = [
  {label: "1 - 5", value: 5},
  {label: "6 - 20", value: 20},
  {label: "20 - 100", value: 100},
  {label: "100 - 200", value: 200},
  {label: "200+", value: 500},
]

export const NumClients = ({updateValue, onNext, values}: GymRegistrationPageProps) => {
  
  const {type} = values;
  
  let description;
  let options = OPTIONS;
  switch(type){
    case "GYM":
      description = "How many clients does your gym have?";
      break;
    case "GYM_GROUP":
      options = OPTIONS.slice(1);
      description = "How many clients does your gym group have?";
      break;
    default:
      description = "How many clients will need access to the system?";
  }
  
  return <OptionSelector updateValue={updateValue} options={options}
                         description={description}
                         field={"numClients"} onNext={onNext}/>
};
