import React from 'react';
import {Checkbox, Icon, Input, InputGroup} from "rsuite";
import ColoredButton from "../../../../../components/ColoredButton/ColoredButton";
import validator from "validator";

// const UPPER = /[A-Z]+/;
// const LOWER = /[a-z]+/;
// const SPECIAL = /[0-9!@£€#$%^&*()-=_+/\\'\[\]`~,.?":{}|<>]/;
// const MIN_PASSWORD_LENGTH = 6;


export const CompanyDetails = ({updateValue, values, onNext}: GymRegistrationPageProps) => {
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [tested, setTested] = React.useState(false);
  
  const handleNext = () => {
    if (!validate()) {
      setTested(true);
      return;
    }
    // initiateGymSelfRegistration(values);
    
    onNext?.();
  }
  
  const validate = () => {
    const newErrors: Record<string, string> = {};
    
    if ((values.firstName ?? "").length < 2) {
      newErrors.firstName = "First name must contain at least two characters";
    }
    if ((values.lastName ?? "").length < 2) {
      newErrors.lastName = "Last name must contain at least two characters";
    }
    if ((values.lastName ?? "").length < 2) {
      newErrors.lastName = "Company name must contain at least two characters";
    }
    if (!validator.isEmail(values.email ?? "dd")) {
      newErrors.email = "Invalid email address";
    }
    if (!values.termsAccepted) {
      newErrors.termsAccepted = "You must agree to the terms to continue";
    }
    
    // if (
    //   (!values.password || (values.password?.length < MIN_PASSWORD_LENGTH))
    //   || !testPw(UPPER)
    //   || !testPw(LOWER)
    //   || !testPw(SPECIAL)
    // ) {
    //   newErrors.password = "Invalid password";
    // }
    
    setErrors(newErrors);
    return !Object.keys(newErrors).length;
  }
  
  const changeValue = <K extends keyof GymRegistrationValues, >(key: K) => (value: GymRegistrationValues[K]) => {
    updateValue(key)(value);
    if (tested) {
      validate();
    }
  }
  //
  // const testPw = (reg: RegExp) => {
  //   return reg.test(password ?? "");
  // }
  
  // const {password} = values;
  
  return (
    <React.Fragment>
      <p style={{marginBottom: "1rem"}}>A little about you to begin:</p>
      <InputGroup style={{marginTop: "0.5rem"}}>
        <InputGroup.Addon style={{width: 125}}>First Name</InputGroup.Addon>
        <Input value={values.firstName ?? ""} onChange={changeValue("firstName")}/>
      </InputGroup>
      <div style={{color: "var(--brand-deny)"}}>{errors.firstName}</div>
      <InputGroup style={{marginTop: "0.5rem"}}>
        <InputGroup.Addon style={{width: 125}}>Last Name</InputGroup.Addon>
        <Input value={values.lastName ?? ""} onChange={changeValue("lastName")}/>
      </InputGroup>
      <div style={{color: "var(--brand-deny)"}}>{errors.lastName}</div>
      <InputGroup style={{marginTop: "0.5rem"}}>
        <InputGroup.Addon style={{width: 125}}>Email</InputGroup.Addon>
        <Input value={values.email ?? ""} onChange={changeValue("email")}/>
      </InputGroup>
      <div style={{color: "var(--brand-deny)"}}>{errors.email}</div>
      <InputGroup style={{marginTop: "0.5rem"}}>
        <InputGroup.Addon style={{width: 125}}>Company Name</InputGroup.Addon>
        <Input value={values.companyName ?? ""} onChange={changeValue("companyName")}/>
      </InputGroup>
      <div style={{color: "var(--brand-deny)"}}>{errors.companyName}</div>
      {/*<InputGroup style={{marginTop: "0.5rem"}}>*/}
      {/*  <InputGroup.Addon style={{width: 125}}>Password</InputGroup.Addon>*/}
      {/*  <Input type={"password"} value={password ?? ""} onChange={changeValue("password")}/>*/}
      {/*</InputGroup>*/}
      {/*{*/}
      {/*  (!!password || !!errors.password) && (*/}
      {/*    <div className={"neu"} style={{margin: "1rem 0 2rem"}}>*/}
      {/*      Password must contain:*/}
      {/*      <ul>*/}
      {/*        <li*/}
      {/*          style={{color: password && password.length >= MIN_PASSWORD_LENGTH ? "var(--brand-confirm)" : "var(--brand-deny"}}>Minimum*/}
      {/*          6 characters*/}
      {/*        </li>*/}
      {/*        <li style={{color: testPw(UPPER) ? "var(--brand-confirm)" : "var(--brand-deny"}}>An upper case letter</li>*/}
      {/*        <li style={{color: testPw(LOWER) ? "var(--brand-confirm)" : "var(--brand-deny"}}>A lower case letter</li>*/}
      {/*        <li style={{color: testPw(SPECIAL) ? "var(--brand-confirm)" : "var(--brand-deny"}}>A number or special*/}
      {/*          character*/}
      {/*        </li>*/}
      {/*      </ul>*/}
      {/*    </div>*/}
      {/*  )*/}
      {/*}*/}
      
      <Checkbox checked={!!values.termsAccepted} onChange={(_, checked) => changeValue("termsAccepted")(checked)}>
        I accept the <a href={"https://vivifit.co/terms-conditions"} target={"_blank"} rel={"noreferrer"}><strong>Terms
        & Conditions </strong><Icon style={{color: "var(--grey-3)"}} icon={"external-link"}/></a>
      </Checkbox>
      <div style={{color: "var(--brand-deny)"}}>{errors.termsAccepted}</div>
      <div style={{position: "absolute", bottom: "1rem", right: "1rem"}}>
        <ColoredButton color={"good"} label={"Next"} onClick={handleNext}/>
      </div>
    </React.Fragment>
  );
};
