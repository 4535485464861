import { useRef, useState } from "react";
import { useClickAway } from "react-use";
import cn from 'classnames';
import s from "./Menu.module.scss"


type MenuItem = {
  label: string;
  trigger?: React.ReactNode;
  onClick: () => void;
}

export const DropDownMenu = ({ menuItems, trigger, title }: { menuItems: MenuItem[], trigger?: React.ReactNode, title?: string }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  useClickAway(ref, () => {
    setMenuOpen(false);
  }, ['click']);

  const toggleMenu = () => {
    setMenuOpen(prev => !prev);
  }

  const handleMenuClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    toggleMenu();
    console.log('clicked');
  }

  const handleMenuKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      toggleMenu();
    }
  }

  const handleItemClick = (item: MenuItem) => {
    item.onClick();
    setMenuOpen(false);
  }


  return (
    <div
      className={cn(s.surround)}
      ref={ref}
    >
      <div
        className={cn(s.trigger)}
        onClick={handleMenuClick}
        onKeyDown={handleMenuKeyDown}
        tabIndex={0}
        role="button"
        aria-label="Open menu"
        aria-pressed={menuOpen}
      >
        {trigger || <MenuIcon />}
      </div>
      {
        menuOpen && (
          <div className={cn(s.menu)}>
            {title && <div className={cn(s.menuTitle)}>{title}</div>}
            {
              menuItems.map((item, index) => (
                <button key={index} onClick={() => handleItemClick(item)}>{item.label}</button>
              ))
            }
          </div>
        )
      }
    </div>
  )
}

const MenuIcon = () => {
  return (
    <div className={cn(s.menuIcon)}>
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="18"
        height="18"
        viewBox="0 0 24 24"
        fill="none"
        stroke="#aaa"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
        className="lucide lucide-ellipsis"
      >
        <circle cx="12" cy="5" r="1" />
        <circle cx="12" cy="12" r="1" />
        <circle cx="12" cy="19" r="1" />
      </svg>
    </div>
  )
}

