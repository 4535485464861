import React from 'react';

import cn from "classnames";
import * as s from "./PlaceholderCard.module.scss"


interface IOwnProps {
  lines?: number
}

export const PlaceholderCard = React.memo(({lines = 3}: IOwnProps) => {
  
  return (
    <div className={cn(s.placeholderSurround)}>
      {
        Array.from({length: Math.min(lines - 1)}).map((_, index) => {
          return (
            <div key={index} className={cn(s.placeholderItem, s.textLine)} />
          )
        })
      }
      <div className={cn(s.placeholderItem, s.textLine)} style={{width: "80%"}} />
    </div>
  );
});
