import gql from "graphql-tag";

export const authenticateCognito = gql`
  mutation AuthenticateCognito($cognitoToken: String!) {
    authenticateCognito(cognitoToken: $cognitoToken){
      token
    }
  }
`


export const authenticateProxy = gql`
  mutation AuthenticateProxy($cognitoToken: String!, $clientProfileId: ID!) {
    authenticateProxy(cognitoToken: $cognitoToken, clientProfileId: $clientProfileId){
      id
      email
      accountStatus
      clientProfileId
      roles
      token
    }
  }
`
