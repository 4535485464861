import React, {FC} from 'react';
import cn from "classnames";
import s from "./BrandedSplitPage.module.css";
import logo from "../../assets/logo-white-transparent.png";
import {Link} from 'react-router-dom';

interface IOwnProps {
  center?: boolean;
  hideBranding?: boolean;
}

export const BrandedSplitPage: FC<IOwnProps> = ({children, hideBranding, center}) => {
  
  if (hideBranding) return children as any;
  
  return (
    <div className={cn(s.bg)}>
      <div className={cn(s.darkSection)}>
        <Link to={"/"} style={{textAlign: "center", width: "100%"}}>
          <img src={logo} className={cn(s.logo)}/>
        </Link>
      </div>
      <div className={cn(s.mainSection)}>
        {
          center && (
            <div style={{
              display: center ? "flex" : undefined,
              justifyContent: "center",
            }}>
              <div style={{maxWidth: 1000}}>
                {children}
              </div>
            </div>
          )
        }
        {!center && children}
      </div>
    </div>
  );
};
