import { useRef, useState } from "react";
import { observer } from "mobx-react-lite";
import { useTrainingPlan, Day } from "@vivifit/fitdo-utils";
import cn from 'classnames';
import s from './TrainingPlanWeekEditor.module.scss';
import { useClickAway } from "react-use";
import { DropDownMenu } from "../../components/DropDownMenu/DropDownMenu";

export const TrainingPlanWeekEditor = observer(() => {
  const store = useTrainingPlan();
  const { selectedWeek } = store;
  if (!selectedWeek) {
    return null;
  }
  return (
    <div className={cn(s.surround)}>
      {selectedWeek.days.map((day, index) => (
        <DayEditor key={index} day={day} />
      ))}
      <button
        className={cn(s.addDayButton)}
        onClick={() => store.addDayToSelectedWeek()}
      >
        + Day
      </button>
    </div>
  );
})

const DayEditor = observer(({ day }: { day: Day }) => {
  const store = useTrainingPlan();
  return (
    <div className={cn(s.daySurround)}>
      <div className={cn(s.dayText)}>Day {day.dayNo + 1}</div>

      <div className={cn(s.workoutsSurround)}>
        {
          day.workouts.map((workout, index) => (
            <WorkoutItem
              key={index}
              workout={workout}
              onDelete={() => store.deleteWorkoutFromDay(day.dayNo, workout.id)}
              onEdit={() => store.selectedEditingWorkoutId = workout.id}
              onMarkCompleted={() => store.markWorkoutAsCompleted(workout.identifier)}
            />
          ))
        }
      </div>

      <button
        className={cn(s.addWorkoutButton)}
        onClick={() => store.selectedAddingWorkoutDayNo = day.dayNo}
      >
        Add Workout
      </button>
    </div>
  );
})

const WorkoutItem = observer(({ workout, onDelete, onEdit, onMarkCompleted }: { workout: Day["workouts"][number], onDelete: () => void, onEdit: () => void, onMarkCompleted: () => void }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const menuRef = useRef<HTMLDivElement>(null);

  useClickAway(menuRef, () => setMenuOpen(false), ['click']);

  const handleAction = (action: () => void) => {
    setMenuOpen(false);
    action();
  }

  return (
    <div
      className={cn(s.workoutItem)}
      onClick={() => setMenuOpen(prev => !prev)}
      ref={menuRef}
    >
      <label>{workout.name}</label>
      <DropDownMenu
        menuItems={[
          { label: "Edit", onClick: () => handleAction(onEdit) },
          { label: "Delete", onClick: () => handleAction(onDelete) },
          { label: "Mark Completed", onClick: () => handleAction(onMarkCompleted) },
        ]}
      />

    </div>
  );
})

