import client from "../apollo/client";
import gql from "graphql-tag";
import React, {createContext, useContext, useEffect, useState} from "react";
import {useLocation} from "react-use";
import {Helmet} from "react-helmet";

export const domainContext = (domain: string) => {
  return client.query<{ getBrandingForDomain: IBranding }>({
    query: gql`
          query GetBrandingForDomain($domain: String!) {
            getBrandingForDomain(domain: $domain) {
              logoUrl
              logoOnDarkUrl
              colors
            }
          }
        `,
    variables: {
      domain
    }
  })
    .then(({data}) => {
      return data?.getBrandingForDomain;
    })
    .catch((error) => {
      return null;
    })
}


export const DomainContext = createContext<ReturnType<typeof useDomainContextValue> | null>(null);
export const DomainContextProvider = ({children}: { children: any }) => {
  const value = useDomainContextValue();
  return (
    <DomainContext.Provider value={value}>
      <Helmet><title>Home</title></Helmet>
      {children}
    </DomainContext.Provider>
  )
}

const useDomainContextValue = () => {
  const [branding, setBranding] = useState<IBranding | null>(null);
  const [ready, setReady] = React.useState(false);
  const {hostname} = useLocation();
  
  useEffect(() => {
    if (!hostname) return;
    domainContext(hostname)
      .then((branding) => {
        setBranding(branding);
        if (branding?.colors) {
          Object.entries(branding?.colors).forEach(([key, val]) => {
            document.documentElement.style.setProperty(key, val);
          });
        }
      })
      .finally(() => {
        setReady(true);
      });
  }, [hostname]);
  
  return {
    branding,
    ready
  }
}

export const useDomainContext = () => {
  const context = useContext(DomainContext);
  if (context === null) {
    throw new Error("useDomainContext must be used within a DomainContextProvider");
  }
  return context;
}
