'use client';
import React, { useMemo, useState } from "react";
import cn from "classnames";
import * as s from "./DateDropdown.module.scss";
import moment, { MomentInput } from "moment";

const currentYear = moment().year();

const YEARS = Array.from({ length: 100 }, (_, i) => {
  const year = currentYear - 100 + i;
  return {
    label: `${year}`,
    value: year,
  };
});

// Months where the label is the word, and the value is the index
const MONTHS = Array.from({ length: 12 }, (_, i) => {
  return {
    label: moment().month(i).format("MMMM"),
    value: i,
  };
});

// Days where the label is the number, and the value is the index
const DAYS = Array.from({ length: 31 }, (_, i) => {
  return {
    label: `${i + 1}`,
    value: i + 1,
  };
});

export const DateDropdown = React.memo(
  ({
    value,
    onChange,
  }: {
    value?: MomentInput;
    onChange: (d: Date) => void;
  }) => {
    const initialDate = useMemo(() => {
      if (value) return moment(value).toDate();
    }, [value]);

    const [{ year, month, day }, setValues] = useState({
      year: initialDate?.getFullYear(),
      month: initialDate?.getMonth(),
      day: initialDate?.getDate(),
    });

    React.useEffect(() => {
      if (initialDate) {
        setValues({
          year: initialDate.getFullYear(),
          month: initialDate.getMonth(),
          day: initialDate.getDate(),
        });
      }
    }, [initialDate]);

    const manage = (period) => (value) => {
      const nextValue = { year, month, day, [period]: value };
      setValues(nextValue);
      if (["year", "month", "day"].every((period) => nextValue[period])) {
        onChange(getDate(nextValue.year, nextValue.month, nextValue.day));
      }
    };

    const invalidDay = moment()
      .startOf("day")
      .set({ year, month, date: day })
      .isAfter(moment().startOf("day").set({ year, month }).endOf("month"));

    return (
      <>
        <div className={cn(s.surround)}>
          <Drop
            value={month}
            onChange={manage("month")}
            options={MONTHS}
            placeholder="Month"
          />
          <Drop
            isInvalid={invalidDay}
            value={day}
            onChange={manage("day")}
            options={DAYS}
            placeholder="Day"
          />
          <Drop
            value={year}
            onChange={manage("year")}
            options={YEARS}
            placeholder="Year"
          />
        </div>
      </>
    );
  }
);

const Drop = ({
  value,
  onChange,
  options,
  isInvalid,
  placeholder = "Choose",
}: any) => {
  return (
    <select
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
      className={cn(s.select, { [s.isInvalid]: isInvalid })}
    >
      <option value="" disabled selected hidden>
        {placeholder}
      </option>
      {options.map((option) => (
        <option key={option.value} value={option.value}>
          {option.label}
        </option>
      ))}
    </select>
  );
};

const getDate = (year, month, date) => {
  return moment().set({ year, month, date }).toDate();
};
