import React, {FunctionComponent} from 'react';
import { BrandedSplitPage } from '../../../../components/BrandedSplitPage/BrandedSplitPage';
import cn from "classnames";
import s from "./GymSelfRegister.module.css"

import {GymType} from "./components/GymType";
import {Welcome} from "./components/Welcome";
import ColoredButton from "../../../../components/ColoredButton/ColoredButton";
import {NumTrainers} from "./components/NumTrainers";
import {CompanyDetails} from "./components/CompanyDetails";
import {NumClients} from "./components/NumClients";
import {Plans} from "./components/Plans";


const steps: {title?: string; nextLabel?: string; disableNext?: boolean; Comp: FunctionComponent<IRegistrationPage<GymRegistrationValues>>}[] = [
  {title: "Welcome", nextLabel: "Get Started", Comp: Welcome},
  {title: "Your details", Comp: CompanyDetails, disableNext: true},
  {title: "Let's get to know you", Comp: GymType, disableNext: true},
  {title: "Your Gym", Comp: NumTrainers, disableNext: true},
  {title: "Your Clients", Comp: NumClients, disableNext: true},
  {title: "Choose your plan", Comp: Plans, disableNext: true},
];


const seed = Math.floor(Math.random()*10000000);
const gymRegEmail = process.env.GYM_SAMPLE_EMAIL;
const DEFAULTS: Partial<GymRegistrationValues> = gymRegEmail ? {
  firstName: `LeoGroup${seed}`,
  lastName: "Leo",
  email: gymRegEmail.replace("_SEED_", String(seed)),
  companyName: "The Gym Company",
  termsAccepted: true,
} : {}

export const GymSelfRegister = () => {
  
  const [step, setStep] = React.useState(0);
  const [values, setValues] = React.useState<Partial<GymRegistrationValues>>(DEFAULTS);
  
  const Comp = steps[step].Comp;
  const title = steps[step].title;
  const disableNext = steps[step].disableNext;
  
  const onNext = step < (steps.length - 1) ? () => setStep(prev => prev + 1) : undefined;
  const onPrevious = step > 0 ? () => setStep(prev => prev - 1) : undefined;
  
  const updateValue = <K extends keyof GymRegistrationValues, >(key: K) => (value: GymRegistrationValues[K]) => {
    setValues(prev => ({
      ...prev,
      [key]: value
    }))
  }
  
  // return (
  //   <BrandedSplitPage>
  //     <div style={{display: "flex", alignItems: "center", justifyContent: "center", height: "100%", fontSize: "1rem"}}>
  //       Self-registration coming soon!&nbsp;<p>Please contact <strong><a href={"mailto:info@vivifit.co?subject=Gym%20Registration%20Enquiry"} style={{textDecorationLine: "underline"}}>info@vivifit.co</a></strong> to get set up straight away!</p>
  //     </div>
  //
  //   </BrandedSplitPage>
  // )
  
  return (
    <BrandedSplitPage>
      <div style={{padding: "3rem", width: "100%", position: "relative", minHeight: "100%"}}>
        <div className={cn("neu", s.stepSurround)}>
          {title && <h2 style={{margin: "2rem 0"}}>{title}</h2>}
          <Comp {...{values, updateValue, onNext}} />
          
        </div>
        <div className={cn(s.progressSurround)}>
          <div className={cn(s.progressInner)} style={{width: `${Math.max(5, Math.round(step * 100 / steps.length))}%`}} />
        </div>
  
        {onPrevious && (
          <div className={cn(s.previous)} style={{position: "fixed", bottom: "1rem",}} >
            <ColoredButton color={"neutral"} label={"Previous"} onClick={onPrevious}/>
          </div>
        )}
        {
          !disableNext && onNext && (
            <div style={{position: "absolute", bottom: "1rem", right: "1rem"}}>
              <ColoredButton color={"neutral"} label={steps[step].nextLabel || "Next"} onClick={onNext}/>
            </div>
          )
        }
        
        <pre style={{fontSize: 12}}>{JSON.stringify({values}, null, 2)}</pre>
      </div>
    </BrandedSplitPage>
  );
};
