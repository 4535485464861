import React from 'react';
import cn from "classnames";
import s from "../../Registration.module.scss"
import {Button, Checkbox, Icon, Input} from "rsuite";
import {GymMembershipType, useGymJoin} from "../GymJoinContext";


const UPPER = /[A-Z]+/;
const LOWER = /[a-z]+/;
const SPECIAL = /[0-9!@£€#$%^&*()-=_+/\\'\[\]`~,.?":{}|<>]/;
const MIN_PASSWORD_LENGTH = 6;

const seed = Math.floor(Math.random() * 10000000);
const gymRegEmail = process.env.GYM_SAMPLE_EMAIL;
const DEFAULTS: Partial<GymRegistrationValues> = gymRegEmail ? {
  firstName: `LeoGroup${seed}`,
  lastName: "Leo",
  email: gymRegEmail.replace("_SEED_", String(seed)),
  companyName: "The Gym Company",
  termsAccepted: true,
} : {}

export const GymJoinForm = () => {
  
  const {values, working, update, errors, testPw, save} = useGymJoin();
  
  const {password} = values;
  
  return (
    
    <section className={cn(s.light)}>
      <article>
        <h1>Sign up</h1>
        <p className={cn(s.intro)}>We're so glad to have you on board! Let's do something great for your clients</p>
        <p>We need to know <b>5 things</b> to open your account</p>
        <div className={cn(s.foldable)}>
          <InputRow disabled={working} label={"First Name"} value={values.firstName} onChange={update("firstName")}
                    error={errors.firstName}/>
          <InputRow disabled={working} label={"Last Name"} value={values.lastName} onChange={update("lastName")}
                    error={errors.lastName}/>
        </div>
        <InputRow disabled={working} label={"Gym or Brand Name"} value={values.companyName}
                  onChange={update("companyName")} error={errors.companyName}/>
        <InputRow disabled={working} label={"E-mail"} value={values.email} onChange={update("email")}
                  error={errors.email}/>
        <InputRow disabled={working} type={"password"} label={"Password"} value={values.password}
                  onChange={update("password")}/>
        {
          (!!errors.password) && (
            <div className={"neu"} style={{margin: "1rem 0 2rem"}}>
              Password must contain:
              <ul>
                <li
                  style={{color: password && password.length >= MIN_PASSWORD_LENGTH ? "var(--brand-confirm)" : "var(--brand-deny"}}>Minimum
                  6 characters
                </li>
                <li style={{color: testPw(UPPER) ? "var(--brand-confirm)" : "var(--brand-deny"}}>An upper case
                  letter
                </li>
                <li style={{color: testPw(LOWER) ? "var(--brand-confirm)" : "var(--brand-deny"}}>A lower case letter
                </li>
                <li style={{color: testPw(SPECIAL) ? "var(--brand-confirm)" : "var(--brand-deny"}}>A number or special
                  character
                </li>
              </ul>
            </div>
          )
        }
        <Checkbox className={cn(s.termsCheckbox)} checked={!!values.termsAccepted}
                  onChange={(_, checked) => update("termsAccepted")(checked)}>
          I accept the <a href={"https://vivifit.co/terms-conditions"} target={"_blank"} rel={"noreferrer"}><strong>Terms
          & Conditions </strong><Icon style={{color: "var(--grey-3)"}} icon={"external-link"}/> </a> and <a
          href={"https://vivifit.co/privacy"} target={"_blank"} rel={"noreferrer"}><strong>Privacy
          policy </strong><Icon style={{color: "var(--grey-3)"}} icon={"external-link"}/> </a>
        </Checkbox>
        {
          errors.termsAccepted &&
            <div style={{color: "var(--brand-deny)"}}>You must accept the terms to continue</div>
        }
        <Button disabled={working} appearance={"primary"} onClick={save} style={{float: "right"}}>Save</Button>
      </article>
    </section>
  
  );
};

interface IInputRowProps {
  label: string;
  value?: string;
  type?: "text" | "password";
  error?: string;
  disabled?: boolean;
  onChange(value: string): void;
}

const InputRow = ({label, value, onChange, type = "text", error, disabled}: IInputRowProps) => {
  return (
    <>
      <div className={cn(s.inputRowSurround)}>
        <label><b>{label}</b></label>
        <div className={cn(s.input)}>
          <Input disabled={disabled} type={type} value={value} onChange={onChange}/>
          {!!error && <div style={{color: "var(--brand-deny)"}}>{error}</div>}
        </div>
      </div>
    
    </>
  )
}
