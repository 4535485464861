import React from 'react';
import {gql, useQuery} from "@apollo/client";

export const CLIENT_NAME = gql`
  query ClientName($id: ID!){
    clientName(id: $id)
  }
`;
interface IOwnProps {
  id: string;
  firstName?: string;
  showLoading?: boolean;
}

export const ClientName = ({id, showLoading = true, firstName}: IOwnProps) => {
  
  const { loading, data } = useClientName(id);
  
  return (
    <span>
      {!!firstName && !data?.clientName && firstName} {loading && showLoading ? "Loading" : data?.clientName ?? ""}
    </span>
  );
};

export const useClientName = (id: string) => useQuery<{clientName: string}>(CLIENT_NAME, {
  variables: {id},
  skip: !id,
});
