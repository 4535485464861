import {apiUri, wsUri} from "../config/api";
import {createClient} from "@vivifit/fitdo-utils";
import {ApolloClient} from "@apollo/client";
// import {getToken} from "../helpers/getToken";

const getToken = () => Promise.resolve(localStorage.getItem("@gym:token") || localStorage.getItem("@user:token") || "");
// @ts-ignore
const client: ApolloClient<any> = createClient(apiUri ?? "", wsUri ?? "", getToken);

export default client;
