import React, { FC } from 'react';
import Input from "../../components/Inputs/Input";
import ColoredButton from "../../components/ColoredButton/ColoredButton";
import validator from "validator";
import {useApolloClient} from "@apollo/client";
import {RESET_PASSWORD} from "../../apollo/accountOperations";
import { Auth } from 'aws-amplify';
import {useHistory} from "react-router-dom";
import {Button, TextField} from '@aws-amplify/ui-react';

interface IOwnProps {
  onBack?(): void;
}

enum STATE {
  WAITING,
  SUBMITTED
}

const PasswordResetComponent: FC<IOwnProps> = ({onBack}) => {
  const [state, setState] = React.useState(STATE.WAITING);
  const [email, setEmail] = React.useState("");
  
  const apolloClient = useApolloClient();
  
  const logout = () => {
    Auth.signOut();
    onBack?.();
  }
  
  const submit = () => {
    apolloClient.mutate({
      mutation: RESET_PASSWORD,
      variables: {
        email
      }
    })
      .finally(() => {
        setState(STATE.SUBMITTED);
      });
  }
  
  const emailValid = validator.isEmail(email);
  const emailInvalid = !!email && !emailValid;
  
  if(state === STATE.SUBMITTED){
    return (
      <div style={{backgroundColor: "white", padding: 60, borderRadius: 12, border: "1px solid var(--amplify-components-authenticator-router-border-color)"}}>
        <h2 style={{padding: 0, margin: 0, marginBottom: 20}}>Password reset</h2>
        <p>Thanks - if your account was found, an email has been sent to <strong>{email}</strong></p>
        <p>Please check your spam / junk if you haven't received it in a few minutes</p>
        <div style={{display: "flex", justifyContent: "flex-end", paddingTop: 40}}>
          <ColoredButton color={"neutral"} label={"Back to login"} onClick={logout} />
        </div>
      </div>
      )
  }
  
  return (
    <div style={{backgroundColor: "white", width: "500px", maxWidth: "100%", padding: "60px 60px 10px 60px", borderRadius: 12, border: "1px solid var(--amplify-components-authenticator-router-border-color)"}}>
  
    {/*<h2 style={{padding: 0, margin: 0, marginBottom: 20}}>Reset password</h2>*/}
      {/*<Input style={{borderBottomColor: emailValid ? "var(--brand-confirm)" : emailInvalid ? "var(--brand-deny)" : "var(--brand-primary)"}} value={email} onChange={setEmail} placeholder={"Email address"} />*/}
      <TextField
        onChange={(e: any) => {
          setEmail(e.target.value);
        }}
        label="Email address"
        value={email}
        hasError={emailInvalid}
        placeholder={"Enter your email address"}
        width={"100%"}
        errorMessage={"Please enter a valid email"}
      />
      <div style={{display: "flex", justifyContent: "flex-end", paddingTop: 40}}>
        {/*<ColoredButton disabled={emailInvalid || !email} color={"neutral"} label={"Submit"} onClick={submit} />*/}
      </div>
      <Button
        isFullWidth={true}
        variation="primary"
        loadingText=""
        disabled={emailInvalid || !email}
        onClick={submit}
        ariaLabel="submit"
      >
        Submit
      </Button>
      {
        onBack && (
          <Button
            isFullWidth={true}
            variation="link"
            loadingText=""
            onClick={onBack}
            ariaLabel="submit"
          >
            Cancel
          </Button>
        )
      }

    </div>
  );
};

export default PasswordResetComponent;
