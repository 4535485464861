import React from "react";
import { BrowserFrame } from "../../../../../components/3d/BrowserFrame";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";

interface IOwnProps {}

export const ProductOverviewScreens = (props: IOwnProps) => {
  return (
    <div>
      <Carousel
        showArrows={false}
        showThumbs={false}
        showStatus={false}
        autoPlay
        interval={4000}
        infiniteLoop
      >
        <BrowserFrame src={"/images/screenshots/client-overview.png"} />
        <BrowserFrame src={"/images/screenshots/calendar.png"} />
        <BrowserFrame src={"/images/screenshots/custom-branding.png"} />
        <BrowserFrame src={"/images/screenshots/workout-templates.png"} />
        <BrowserFrame src={"/images/screenshots/custom-forms.png"} />
        <BrowserFrame src={"/images/screenshots/daily-log-settings.png"} />
      </Carousel>
    </div>
  );
};
