import dateFormat from "dateformat";
import moment, {MomentInput} from "moment-timezone";
export const addDays = (d: Date, n: number, includeTime = true) => (new Date(d.getFullYear(), d.getMonth(), d.getUTCDate() + n, includeTime ? d.getHours() : 3, includeTime ? d.getMinutes() : 0))
export const today = ((d:Date) => new Date(d.getFullYear(), d.getMonth(), d.getDate()))(new Date());
export const datesEqual = (a: Date, b: Date) => a.getFullYear() === b.getFullYear() && a.getMonth() === b.getMonth() && a.getDate() === b.getDate();
export const startOfCurrentWeek = () => getStartOfWeek(new Date())
export const getStartOfWeek = (d: Date) => new Date(d.getFullYear(), d.getMonth(), d.getDate() - (d.getDay() === 0 ? 6 : d.getDay() - 1));
export const getStartOfMonth = (d: Date) => new Date(d.getFullYear(), d.getMonth(), 1);
export const getStartOfNextMonth = (d: Date) => new Date(d.getFullYear(), d.getMonth() + 1, 1);
export const getEndOfMonth = (d: Date) => new Date(d.getFullYear(), d.getMonth() + 1, 0);
export const getEndOfNextMonth = (d: Date) => new Date(d.getFullYear(), d.getMonth() + 2, 0);

export const dateDiff = (d1: Date, d2: Date) => (addDays(d2, 0).getTime() - addDays(d1, 0).getTime()) / 24 / 60 / 60 / 1000
export const stringFormat = (d: Date) => dateFormat(d, "yyyy-mm-dd");


// Helper function that will adjust offsets of a target date to match the offset of the original date, if one is using summer time and the other isn't
export const adjustOffset = (originalDate: MomentInput, targetDate: MomentInput, timezone: string = "Europe/London") => {
  const originalOffset = moment(originalDate).tz(timezone).utcOffset();
  const targetOffset = moment(targetDate).tz(timezone).utcOffset();
  const offsetDiff = targetOffset - originalOffset;
  return moment(targetDate).add(offsetDiff, "minutes").toDate();
  
}


