import React from 'react';
import cn from "classnames";
import s from "../../Registration.module.scss";
import {WholeScreenLoading} from "../../../../../components/WholeScreenLoading/WholeScreenLoading";
import {CompanyLogo} from "../../../../../components/CompanyLogo/CompanyLogo";
import axios from "axios";
import {apiUri} from "../../../../../config/api";

interface IOwnProps {
  gymId?: string;
}

export const BrandArea = ({gymId}: IOwnProps) => {
  const [gym, setGym] = React.useState();
  
  React.useEffect(() => {
    if(!gymId) return;
    axios.get(`${apiUri}/gymDetails/${gymId}`)
      .then(result => {
        if(result.data.gym){
          setGym(result.data.gym);
        }
        console.log({result});
      })
      .catch(error => {
        console.log({error})
      })
  }, [gymId]);
  
  
  const brand = extractBrand(gym);
  const colors = brand?.colors;
  
  if(!gym){
    return (
      <WholeScreenLoading loading={true} loadingText={"Loading Package"} backgroundColor={"#111"} color={"#FFF"} />
    )
  }
  
  return (
    <section
      className={cn(s.dark, s.noBackground)}
      style={{
        backgroundColor: colors?.["--brand-primary-darker"],
        color: colors?.["--brand-text-on-primary"],
        // background: `radial-gradient(ellipse at center, white, ${colors?.["--brand-primary-darker"]}, ${colors?.["--brand-primary-darkest"]})`,
        // background: `radial-gradient(ellipse at center, ${colors?.["--brand-primary-darker"]}, ${colors?.["--brand-primary-darkest"]})`,
        // background: `radial-gradient(ellipse at center,  ${colors?.["--brand-background"]},  ${colors?.["--brand-background-dark"]})`,
        background: `radial-gradient(ellipse at center, ${colors?.["--brand-primary"]}, ${colors?.["--brand-primary-darkest"]})`,
      }}
    >
      <article className={cn(s.productSurround)}>
        {
          !!gym && (
            <div style={{display: "flex", flexDirection: "column", alignItems: "center"}}>
              <CompanyLogo gym={gym} on={"dark"} />
              {/*<pre style={{fontSize: 12}}>{JSON.stringify(data?.package, null, 2)}</pre>*/}
              {/*<pre style={{fontSize: 12}}>{JSON.stringify({colors}, null, 2)}</pre>*/}
            </div>
          )
        }
      </article>
    </section>
  );
};

const extractBrand = (gym?: IGym) => {
  return gym?.branding ?? gym?.group?.branding;
}

