
import { observer } from "mobx-react-lite";
import { Phase, useTrainingPlan } from "@vivifit/fitdo-utils";
import cn from 'classnames';
import s from './TrainingPlanPhaseManager.module.scss';
import { DropDownMenu } from "../../components/DropDownMenu/DropDownMenu";

export const TrainingPlanPhaseManager = observer(({ editable = true, showTitle = true }: { editable?: boolean, showTitle?: boolean }) => {
  const store = useTrainingPlan();
  return (

    <div className={cn(s.phaseContainer)}>
      {
        showTitle && (
          <div className={cn(s.phaseTitle)}>Phase</div>
        )
      }
      {
        store.phases.slice().sort((a, b) => a.order - b.order).map((phase, index) => {
          const selected = store.selectedPhase?.id === phase.id;
          return (<PhaseButton key={index} phase={phase} selected={selected} editable={editable} />)
        })
      }
      {
        editable && (
          <DropDownMenu
            menuItems={[
              { label: 'Create New', onClick: () => store.addPhase() },
              { label: 'Create from Template', onClick: () => store.showAddPhaseFromTemplate(true) },
            ]}
            title="Add Phase"
            trigger={(
              <div className={cn(s.plusButton)}>
                +
              </div>
            )}
          />
        )
      }
    </div>
  );
})

const PhaseButton = observer(({ phase, selected, editable }: { phase: Phase, selected: boolean, editable: boolean }) => {
  const store = useTrainingPlan();

  const handleDeletePhase = () => {
    store.deletePhase(phase.id);
  }

  const handleDuplicatePhase = () => {
    store.duplicatePhase(phase.id);
  }

  const handleSaveAsTemplate = () => {
    store.selectedSavingPhaseAsTemplate = phase;
  }

  const handleAddWeekFromTemplate = () => {
    store._selectedPhaseToAddWeekFromTemplate = phase;
  }

  const select = () => store.selectPhase(phase.order)

  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' || e.key === ' ') {
      select();
    }
  }

  return (
    <div
      className={cn(s.phaseButton, s.noFilter, { [s.selected]: selected })}
      onClick={select}
      tabIndex={0}
      onKeyDown={handleKeyDown}
    >
      {phase.name || `Phase ${phase.order + 1}`}
      {
        editable && (
          <DropDownMenu
            menuItems={[
              { label: 'Delete', onClick: handleDeletePhase },
              { label: 'Clone', onClick: handleDuplicatePhase },
              { label: 'Save as Template', onClick: handleSaveAsTemplate },
              { label: 'Add Week from Template', onClick: handleAddWeekFromTemplate },
            ]}
          />
        )
      }
    </div>
  )
})

const MenuIcon = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="18"
      height="18"
      viewBox="0 0 24 24"
      fill="none"
      stroke="#aaa"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      className="lucide lucide-ellipsis"
    >
      <circle cx="12" cy="5" r="1" />
      <circle cx="12" cy="12" r="1" />
      <circle cx="12" cy="19" r="1" />
    </svg>
  )
}

