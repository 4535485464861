import React from 'react';

export const Welcome = () => {
  
  return (
    <div>
      <p>We're very excited that you've chosen us to help you level up your Client Experience. </p>
      <br/>
      <p>Let's set up your account! </p>
      <br/>
    </div>
  );
};
