import React from 'react';
import {OptionSelector} from "./OptionSelector";

const OPTIONS: {label: string, value: number}[] = [
  {label: "1", value: 1},
  {label: "2 - 5", value: 5},
  {label: "6 - 20", value: 20},
  {label: "20 - 100", value: 100},
]

export const NumTrainers = ({updateValue, onNext, values}: GymRegistrationPageProps) => {
  
  const {type} = values;
  
  const description = "How many staff (trainers, admin, reception, etc.) will need access to the system?";
  let options = OPTIONS;
  switch(type){
    case "GYM_GROUP":
      options = OPTIONS.slice(1);
      break;
  }
  
  return <OptionSelector updateValue={updateValue} options={options}
                         description={description}
                         field={"numTrainers"} onNext={onNext}/>
};
