import React from 'react';
import {useApolloClient} from "@apollo/client";
import {ME_QUERY} from "../apollo/userProfileOperations";
import {useAuthenticator} from "@aws-amplify/ui-react";
import {handleSignIn} from "../helpers/authenticationHelpers";
import gql from "graphql-tag";
import {Notification} from "rsuite";

interface IProfileContext {
  loaded: boolean;
  userId?: string;
  clientProfileId?: string;
  trainerProfileId?: string;
  firstName?: string;
  managedGyms: IGym[];
  preferredGym?: IGym;
  gymGroupPreferences?: IGymGroup["preferences"];
  selectPreferredGym?(gym: string, onFinally?: () => void): void;
}

const ProfileContext = React.createContext<IProfileContext>({loaded: false, managedGyms: []})

export const ProfileContextProvider = ({children}: React.PropsWithChildren<{}>) => {
  const [data, setData] = React.useState<IProfileContext>({
    userId: undefined,
    clientProfileId: undefined,
    firstName: undefined,
    managedGyms: [],
    loaded: false
  });
  
  const {user} = useAuthenticator(context => [context.user]);
  const client = useApolloClient();
  
  
  React.useEffect(() => {
    if (user) {
      fetchProfile();
    }
  }, [user]);
  
  const fetchProfile = async () => {
    await handleSignIn();
    client.query<{ me: IUser, managedGyms: IGym[] }>({query: ME_QUERY})
      .then(result => {
        setData({
          userId: result.data?.me?.id,
          clientProfileId: result.data?.me?.clientProfile?.id,
          firstName: result.data?.me?.clientProfile?.firstName,
          trainerProfileId: result.data?.me?.trainerProfile?.id,
          managedGyms: result.data.managedGyms,
          preferredGym: result.data?.me?.clientProfile?.preferredGym,
          gymGroupPreferences: result.data?.me?.clientProfile?.preferredGym?.group?.preferences,
          loaded: true,
        })
      })
      .catch(err => {
        console.log("Error getting `me`", err);
      })
  }
  
  const selectPreferredGym = (gymId: string, onFinally?: () => void) => {
    client.mutate({
      mutation: SELECT_PREFERRED_GYM,
      variables: {gymId},
      refetchQueries: ["MyProfile"]
    })
      .then(async () => {
        await fetchProfile();
      })
      .catch((error) => {
        Notification.error({
          type: "Error changing gyms",
          text1: "We're sorry - an error occurred whilst trying to set your preferred gym"
        });
      })
      .finally(onFinally)
  }
  
  
  return (
    <ProfileContext.Provider value={{...data, selectPreferredGym}}>
      {children}
    </ProfileContext.Provider>
  );
};

const SELECT_PREFERRED_GYM = gql`
  mutation SelectPreferredGym($gymId: ID!){
    selectPreferredGym(gymId: $gymId){
      id
    }
  }
`


export const useProfile = () => React.useContext(ProfileContext);
