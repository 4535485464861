import React, {FC, useContext} from "react";
import {uniqBy} from "lodash";

interface IHelpContext {
  addTags(tags: Tag[]): void;
  removeTags(tags: HelpTag[]): void;
  tags: Tag[];
}

type Tag = {value: HelpTag; priority?: number}

const noop = () => {console.log("Help context not ready yet")}

const HelpContext = React.createContext<IHelpContext>({
  addTags: noop,
  removeTags: noop,
  tags: []
});


export const HelpContextProvider: FC = ({children}) => {
  const [tags, setTags] = React.useState<IHelpContext["tags"]>([]);
  
  const addTags = (tags: Tag[] | HelpTag[] | HelpTag) => {
    const _tags = processTags(tags);
    setTags(prev => uniqBy([...prev, ..._tags], t => t.value))
  };
  
  const removeTags = (tags: HelpTag[]) => {
    setTags(prev => [...prev].filter(t => !tags.includes(t.value)))
  }
  
  return (
    <HelpContext.Provider value={{addTags, removeTags, tags}}>
      {children}
    </HelpContext.Provider>
  )
}

export const useHelpContext = () => useContext(HelpContext);

export const useHelp = (tags: Tag[] | HelpTag | HelpTag[]) => {
  const {addTags, removeTags} = useHelpContext();
  
  const _tags = processTags(tags);
  
  React.useEffect(() => {
    addTags(_tags)
    return () => {
      removeTags(_tags.map(t => t.value));
    }
  }, [])
}

const processTags = (tags: Tag[] | HelpTag[] | HelpTag): Tag[] => (
  Array.isArray(tags)
    ? (tags as any[]).map(t => typeof t === "string" ? {value: t, priority: 5} : t)
    // typeof tags[0] === "HelpTag" ? (tags as any).map((t: HelpTag) => ({value: t, priority: 5})) : tags as Tag[]
    : [{value: tags, priority: 5}]
).map((t: Tag) => ({...t, priority: t.priority ?? 5}));
