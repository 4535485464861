import React from 'react';
import cn from "classnames";
import s from "./DynamicImportErrorBoundary.module.scss"
import {FaRecycle, FaSync} from "react-icons/all";

interface IOwnProps {

}

export class DynamicImportErrorBoundary extends React.Component<{ fallback?: React.ReactElement }, { hasError: boolean; dynamicImportError: boolean; retried: boolean }> {
  // Failed to fetch dynamically imported module
  
  constructor(props) {
    super(props);
    this.state = {hasError: false, dynamicImportError: false, retried: false};
  }
  
  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    
    console.log(error);
    
    
    if (error.message?.includes("Failed to fetch dynamically imported module")) {
      const currentUrl = new URL(window.location.href);
      const params = new URLSearchParams(currentUrl.search);
      const retried = params.get("retried");
      
      if (!retried || (retried === "false")) {
        params.set("retried", "true");
        currentUrl.search = params.toString();
        window.location.href = currentUrl.toString();
      }
      return {
        dynamicImportError: true,
        hasError: false,
      }
    }
    
    return {
      hasError: true,
      dynamicImportError: false,
    };
  }
  
  componentDidCatch(error, info) {
    console.log({error, info})
  }
  
  reload = () => {
    window.location.reload();
  }
  
  render() {
    
    if (this.state.dynamicImportError) {
      return (
        <div className={cn(s.surround)}>
          <a href={"#"} onClick={this.reload}>
            <div className={cn(s.content)}>
              <div>
                <FaSync size={28} style={{marginBottom: 20}}/>
              </div>
              This site has been updated since you last loaded the page. Please refresh to see the latest version
            </div>
          </a>
        </div>
      )
    }
    
    if (this.state.hasError) {
      return this.props.fallback ?? <div>We're sorry, an error has occurred. Please try refreshing the page</div>;
    }
    
    return this.props.children;
  }
}
