import React, {PropsWithChildren} from "react";
import {useNotification} from "../../../../hooks/useNotification";
import {useApolloClient} from "@apollo/client";
import {useHistory} from "react-router-dom";
import {useGymContext} from "../../../../context/GymContext";
import {useScreenLoading} from "../../../../context/ScreenLoadingContext";
import {handleSignIn, loginWithGym, logout} from "../../../../helpers/authenticationHelpers";
import ReactGA from "react-ga4";
import validator from "validator";
import {CREATE_GYM_ACCOUNT} from "../../../../apollo/accountOperations";
import {Auth} from "aws-amplify";


const GymJoinContext = React.createContext<ReturnType<typeof useGymJoinContextHook> | null>(null);

export const GymJoinContextProvider = ({children, type}: PropsWithChildren<{ type: GymMembershipType }>) => {
  const value = useGymJoinContextHook(type);
  return (
    <GymJoinContext.Provider value={value}>
      {children}
    </GymJoinContext.Provider>
  )
}

export type GymMembershipType = "FULL" | "RECIPE";
export const useGymJoin = () => {
  const context = React.useContext(GymJoinContext);
  if(context === null){
    throw new Error("useGymJoin called outside of GymJoinContext");
  }
  
  return context;
}


const UPPER = /[A-Z]+/;
const LOWER = /[a-z]+/;
const SPECIAL = /[0-9!@£€#$%^&*()-=_+/\\'\[\]`~,.?":{}|<>]/;
const MIN_PASSWORD_LENGTH = 6;

const seed = Math.floor(Math.random() * 10000000);
const gymRegEmail = process.env.GYM_SAMPLE_EMAIL;
const DEFAULTS: Partial<GymRegistrationValues> = gymRegEmail ? {
  firstName: `LeoGroup${seed}`,
  lastName: "Leo",
  email: gymRegEmail.replace("_SEED_", String(seed)),
  companyName: "The Gym Company",
  termsAccepted: true,
} : {}


const useGymJoinContextHook = (type: GymMembershipType) => {
  const [values, setValues] = React.useState<Partial<GymRegistrationValues>>(DEFAULTS);
  const [errors, setErrors] = React.useState<Record<string, string>>({});
  const [tested, setTested] = React.useState(false);
  const [working, setWorking] = React.useState(false);
  const notify = useNotification();
  const apolloClient = useApolloClient();
  const history = useHistory();
  
  const {setGymToken} = useGymContext();
  const {addElement, removeElement} = useScreenLoading();
  
  const selectGym = async (gymId: string) => {
    const nextToken = await loginWithGym(gymId);
    setGymToken(nextToken);
    history.push("/selectSubscription")
  }
  
  React.useEffect(() => {
    ReactGA.event({
      label: "Gym registration started",
      action: "Begin registration",
      category: "billing",
      value: 1
    });
    logout().catch();

  }, [])
  
  const update = <T extends keyof GymRegistrationValues, >(key: T) => (value: GymRegistrationValues[T]) => {
    setValues(prev => ({
      ...prev,
      [key]: value,
    }));
    if (tested) {
      validate();
    }
  }
  
  const testPw = (reg: RegExp) => {
    return reg.test(values.password ?? "");
  }
  
  const validate = () => {
    const newErrors: Record<string, string> = {};
    
    
    if ((values.firstName ?? "").length < 2) {
      newErrors.firstName = "First name must contain at least two characters";
    }
    if ((values.lastName ?? "").length < 2) {
      newErrors.lastName = "Last name must contain at least two characters";
    }
    if ((values.companyName ?? "").length < 2) {
      newErrors.companyName = "Company name must contain at least two characters";
    }
    if (!validator.isEmail(values.email ?? "dd")) {
      newErrors.email = "Invalid email address";
    }
    if (!values.termsAccepted) {
      newErrors.termsAccepted = "You must agree to the terms to continue";
    }
    
    if (
      (!values.password || (values.password?.length < MIN_PASSWORD_LENGTH))
      || !testPw(UPPER)
      || !testPw(LOWER)
      || !testPw(SPECIAL)
    ) {
      newErrors.password = "Invalid password";
    }
    
    setErrors(newErrors);
    return !Object.keys(newErrors).length;
  }
  
  const save = () => {
    
    ReactGA.event({
      label: "Validate entered gym details",
      action: "Validate details",
      category: "billing",
      value: 1
    });
    
    if (!validate()) {
      setTested(true);
      return;
    }
    
    ReactGA.event({
      label: "Register details",
      action: "Send registration",
      category: "billing",
      value: 1
    });
    
    const LOADING_KEY = "CREATING_GYM";
    setWorking(true);
    addElement({id: LOADING_KEY, loadingText: "Building your shiny new Gym"})

    
    const {
      firstName,
      lastName,
      email,
      companyName,
      password
    } = values;
    
    const sentPassword = `${password}`;
    const sentEmail = `${email}`;
    
    notify(
      {
        before: "Hold tight - we're creating your account",
        successMessage: "Done! Let's get started.",
        errorMessage: "Something went wrong",
        showError: true,
      },
      apolloClient.mutate({
        mutation: CREATE_GYM_ACCOUNT,
        variables: {
          firstName,
          lastName,
          email,
          gymName: companyName,
          password,
          type
        }
      })
        .then(async result => {

          await Auth.signIn(sentEmail, sentPassword);
          await handleSignIn();
          await selectGym(result.data.createGymAccount.id);
          ReactGA.event({
            label: "Registration Complete",
            action: "Complete registration",
            category: "billing",
            value: 1
          });
          return result;
        })
    )
      .then((result: any) => {
        console.log({result})
        if(!result || result?.errors || !result?.data){
          throw new Error(result?.errors[0]?.message ?? "Error creating gym");
        }
        history.push("/");
      })
      .finally(() => {
        setWorking(false);
        removeElement(LOADING_KEY);
      })
    
  }
  
  return {
    values, setValues,
    errors, setErrors,
    tested, setTested,
    working, setWorking,
    update, validate,
    save,
    selectGym,
    testPw,
  }
  
}
