import React, {FC} from 'react';

import {useGymContext} from "../context/GymContext";
// import GymNavigator from "./Gym/GymNavigator";
const GymNavigator = React.lazy(() => import('./Gym/GymNavigator'));
const UserNavigator = React.lazy(() => import('./UserNavigator'));
import Loading from "../components/Loading";
import {DynamicImportErrorBoundary} from "../components/DynamicImportErrorBoundary/DynamicImportErrorBoundary";



const MainNavigator: FC = () => {
  
  const {gymToken} = useGymContext();
  
  if (!gymToken) {
    return (
      <React.Suspense fallback={<div style={{minHeight: "100vh", minWidth: "100vw"}}><Loading backgroundColor={"purple"} /></div>}>
        <DynamicImportErrorBoundary>
          <UserNavigator />
        </DynamicImportErrorBoundary>
      </React.Suspense>
    )
  }
  
  return (
    <React.Suspense fallback={<div style={{minHeight: "100vh", minWidth: "100vw"}}><Loading backgroundColor={"pink"} /></div>}>
      <DynamicImportErrorBoundary>
        <GymNavigator />
      </DynamicImportErrorBoundary>
    </React.Suspense>
  )
};


export default MainNavigator;
