import {gql} from "@apollo/client";

export const accountDetails = gql`
  query AccountDetails {
    gymStripeDetails {
      id
      setUpLink
      dashboardLink
      businessName
    }
  }
`;


export const GYM_SUBSCRIPTION_OPTIONS = gql`
  query gymSubscriptionOptions{
    gymSubscriptionOptions {
      id
      productName
      description
      metadata
      prices {
        id
        priceName
        recurrence
        currency
        amount
        currency_options
      }
    }
  }
`

export const MY_INVOICES = gql`
  query MyInvoices {
    myInvoices
  }
`
export const MY_SUBSCRIPTIONS = gql`
  query MySubscriptions {
    mySubscriptions
  }
`

export const MY_PAYMENT_METHODS = gql`
  query MyPaymentMethods {
    myPaymentMethods
  }
`



export const AUTHORISED_USER_ROLES = gql`
  query AuthorisedUserRoles {
    authorisedUserRoles
  }
  `


export const REGISTRATION_STATUS = gql`
  query RegistrationStatus($clientProfileId: ID!) {
    registrationStatus(clientProfileId: $clientProfileId)
  }
`;


export const RESET_PASSWORD = gql`
  mutation ResetPassword($email: String!){
    resetPassword(email: $email){
      success
      message
    }
  }
`;


export const DELETE_SELF = gql`
  mutation DeleteSelf {
    deleteSelf{
      success
      message
    }
  }
`


export const CREATE_GYM_ACCOUNT = gql`
  mutation CreateGymAccount(
    $firstName: String!,
    $lastName: String!,
    $email: String!,
    $gymName: String!,
    $password: String!,
    $type: String!
  ){
    createGymAccount(
      firstName: $firstName,
      lastName: $lastName,
      email: $email,
      gymName: $gymName,
      password: $password,
      type: $type
    ) {
      id
      name
      group {
        id
      }
    }
  }
`;


export const SUBSCRIBE_TO_GYM_MEMBERSHIP = gql`
  mutation SubscribeToGymMembership($priceId: String!, $promotionCode: String) {
    subscribeToGymMembership(priceId: $priceId, promotionCode: $promotionCode){
      success
      message
    }
  }
  
  `;


export const GET_GYM_MEMBERSHIP_CHECKOUT_SESSION = gql`
  mutation GetGymMembershipCheckoutSession($priceId: String!, $promotionCode: String, $currency: String!) {
    getGymMembershipCheckoutSession(priceId: $priceId, promotionCode: $promotionCode, currency: $currency){
      success
      message
    }
  }
  
  `;


export const UPDATE_SETTING_MUTATION = gql`
  mutation UpdateSettings($settings: Json!){
    updateSettings(settings: $settings){
      success
      message
    }
  }
`


export const CHANGE_EMAIL_MUTATION = gql`
  mutation ChangeEmail($newEmail: String!, $clientProfileId: ID!){
    changeClientEmailAddress(newEmail: $newEmail, clientProfileId: $clientProfileId){
      success
      message
    }
  }
  `;

// export const UPDATE_PROFILE_MUTATION = gql`
//   mutation UpdateProfile($firstName: String!, $lastName: String!, $clientProfileId: ID!){
//     updateProfile(firstName: $firstName, lastName: $lastName, clientProfileId: $clientProfileId){
//       id
//       firstName
//       lastName
//     }
//   }
//   `;
export const UPDATE_NAME_MUTATION = gql`
  mutation UpdateName($firstName: String!, $lastName: String!, $clientProfileId: ID!){
    updateName(firstName: $firstName, lastName: $lastName, clientProfileId: $clientProfileId){
      id
    }
  }
  `;
