import * as Sentry from "@sentry/react";
import {Integrations} from "@sentry/tracing";

const environment = import.meta.env.VITE_STAGE === "prod" ? "production" : import.meta.env.VITE_STAGE;

export const configureSentry = () => {
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    integrations: [new Integrations.BrowserTracing()],
    environment,
    enabled: environment === "production",
    release: APP_VERSION,
    beforeSend(event) {
      // if (event.exception) {
      //   Sentry.showReportDialog({ eventId: event.event_id });
      // }
      return {
        ...event,
        breadcrumbs: event.breadcrumbs?.slice(0, 30)
      };
    },
    // We recommend adjusting this value in production, or using tracesSampler
    // for finer control
    tracesSampleRate: 1.0,
  });
}

