import { useTrainingPlan, Week } from "@vivifit/fitdo-utils";
import { observer } from 'mobx-react-lite';
import React, { useEffect } from "react";
import cn from 'classnames';
import s from './TrainingPlanWeekSelector.module.scss';
import { DropDownMenu } from "../../components/DropDownMenu/DropDownMenu";
export const TrainingPlanWeekSelector = observer(({ editable = true }: { editable?: boolean }) => {
  const store = useTrainingPlan();
  const { selectedPhase, _selectedWeekNumber, } = store;
  const weeks = selectedPhase?.weeks;

  useEffect(() => {
    store.gotoNextIncompleteWorkout();
  }, [store._loaded]);

  return (
    <div className={cn(s.container)}>
      <div className={cn(s.weekTitle)}>Week</div>
      <div className={cn(s.weekSurround)}>
        {
          weeks?.map((week, index) => {
            const selected = _selectedWeekNumber === week.weekNo;
            return (
              <WeekButton key={index} week={week} selected={selected} editable={editable} />
            )
          })
        }
      </div>
      {
        editable && (
          <DropDownMenu
            menuItems={[
              { label: 'Create New', onClick: () => store.addWeekToSelectedPhase() },
              { label: 'Add Week from Template', onClick: () => store._selectedPhaseToAddWeekFromTemplate = store.selectedPhase },
            ]}
            title="Add Week"
            trigger={(
              <div className={cn(s.addWeekButton)}>
                +
              </div>
            )}
          />

        )
      }
      {/* <div>
        {
          store
            .getOrderedWorkouts()
            .map(workout =>
              <div key={workout.workout.identifier}>{workout.phaseOrder} {workout.weekNo} {workout.dayNo} {workout.workout.name} {workout.workout.completed ? "Completed" : "Incomplete"} {workout.workout.identifier}</div>
            )
        }
      </div> */}
      {/* <div>Next workout: {store.nextIncompleteWorkout?.workout?.name}</div> */}
    </div>
  )
})

const WeekButton = ({ week, selected, editable }: { week: Week, selected: boolean, editable: boolean }) => {
  const store = useTrainingPlan();
  const select = () => store.selectWeek(week.weekNo);
  const handleKeyDown = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === "Enter" || e.key === " ") {
      select();
    }
  }

  const handleDelete = () => {
    store.deleteWeekFromSelectedPhase(week.weekNo);
  }

  const handleDuplicate = () => {
    store.duplicateWeek(week.weekNo);
  }

  const handleSaveAsTemplate = () => {
    store._selectedSavingWeekAsTemplate = week;
  }

  const label = `Week ${week.weekNo + 1}`;

  return (
    <div
      onKeyDown={handleKeyDown}
      onClick={select}
      tabIndex={0}
      role="button"
      aria-pressed={selected}
      className={cn(s.weekButton, { [s.selected]: selected })}
    >
      {label}
      {
        editable && (
          <DropDownMenu
            title={label}
            menuItems={[
              { label: "Delete", onClick: handleDelete },
              { label: "Clone", onClick: handleDuplicate },
              { label: "Save as Template", onClick: handleSaveAsTemplate },
            ]}
          />
        )
      }
    </div>
  )
}