import React from 'react';
import cn from "classnames";
import s from "./CompanyLogo.module.scss"

interface IOwnProps {
  gym?: IGym;
  on?: "dark" | "light"
}

//
// export const ConnectedCompanyLogo = () => {
//   const {group, branding, gymName} = useGym();
//   const {preferredGym} = useProfile();
//   const logoUrl = (branding.logoUrl ?? group?.branding?.logoUrl ?? preferredGym?.branding?.logoUrl ?? preferredGym?.group?.branding?.logoUrl);
//
//   const name = gymName ?? preferredGym?.name;
//
//
//   if(logoUrl){
//     return <img className={cn(s.brandLogo, s.small)} src={logoUrl} alt={`${name} logo`}/>
//   }
//
//   if(name){
//     return <div style={{fontFamily: "Rock Salt", fontSize: "2rem"}}>{name}</div>
//   }
//
//   return null;
//
// }

export const CompanyLogo = ({gym, on = "light"}: IOwnProps) => {
  
  const logoUrl = extractLogoUrl(gym, on);
  
  if(logoUrl){
    return <img className={cn(s.brandLogo)} src={logoUrl} alt={`${gym?.name} logo`}/>
  }
  
  if(gym?.name){
    return <div style={{fontFamily: "Rock Salt", fontSize: "2rem"}}>{gym?.name}</div>
  }
  
  return null;
};


const extractLogoUrl = (gym?: IGym, on: IOwnProps["on"] = "light") => {
  if(on === "dark") {
    return gym?.branding?.logoOnDarkUrl ?? gym?.group?.branding?.logoOnDarkUrl ?? gym?.branding?.logoUrl ?? gym?.group?.branding?.logoUrl;
  }
  return gym?.branding?.logoUrl ?? gym?.group?.branding?.logoUrl;
}
