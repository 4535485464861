import React from 'react';
import cn from "classnames";
import s from "./BrowserFrame.module.css"


interface IOwnProps {
  src?: string;
}

export const BrowserFrame = ({src = "/images/screenshots/calendar.png"}: IOwnProps) => {
  
  return (
    <div className={cn(s.container)}>
      <div className={cn(s.topBar)}>
        <span className={cn(s.dot)} style={{backgroundColor: "#ED594A"}}/>
        <span className={cn(s.dot)} style={{backgroundColor: "#FDD800"}}/>
        <span className={cn(s.dot)} style={{backgroundColor: "#5AC05A"}}/>
      </div>
      <div className={cn(s.content)}>
        <img className={cn(s.browserContent)} src={src}/>
      </div>
    </div>
  );
};
