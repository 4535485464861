import {useApolloClient, useQuery} from "@apollo/client";
import React from "react";
import {ME_QUERY} from "../apollo/userProfileOperations";
import {useProfile} from "../context/ProfileContext";
import {UPDATE_SETTING_MUTATION} from "../apollo/accountOperations";

const SettingsContext = React.createContext<ReturnType<typeof useSettingsValue> | null>(null);

export const SettingsProvider = ({children}) => {
  const value = useSettingsValue();
  
  return (
    <SettingsContext.Provider value={value}>
      {children}
    </SettingsContext.Provider>
  )
}

export const useSettingsValue = () => {
  const client = useApolloClient();
  const {clientProfileId: id} = useProfile();
  const [updateInProgress, setUpdateInProgress] = React.useState(false);
  
  const query = useQuery<{ me: { id: string; settings: UserSettings } }>(ME_QUERY, {
    fetchPolicy: "cache-first",
    skip: !id,
  });
  
  const {error, data} = query;
  
  const loading = query.loading || updateInProgress;
  
  React.useEffect(() => {
    if (id && !!query && !!query?.refetch) {
      try {
        query?.refetch?.();
      } catch (e) {
        if ((e as any)?.message === "Cannot read properties of undefined (reading 'refetch')") {
          console.log("refetch not available when hotloading")
          return;
        }
        throw e;
      }
      
    }
  }, [query, id])
  
  
  const updateSetting = (settings: Partial<UserSettings>) => {
    setUpdateInProgress(true)
    return client.mutate({
      mutation: UPDATE_SETTING_MUTATION,
      variables: {
        settings,
      },
      refetchQueries: ["Me"]
    }).finally(() => {
      setUpdateInProgress(false)
    })
  }
  
  return React.useMemo(() => ({
    settings: data?.me.settings,
    updateSetting,
    settingsLoaded: !!data?.me,
    error,
    loading,
  }), [JSON.stringify(data?.me.settings), updateSetting, !!data?.me, error, loading]);
}


export const useSettings = () => {
  const context = React.useContext(SettingsContext);
  if (context === null) {
    throw new Error("useSettings must be used within a SettingsProvider");
  }
  return context;
}

export default useSettings;
